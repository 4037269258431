import axios from "axios";
import { blogUrl, baseUrl, constants } from "./constants";

const urlPath = `${baseUrl}`;
const urlPathMode = `${"/" + constants.testPath("mode")}`;
const mode = `${constants.testPath("mode") + "/"}`;
export class ServiceCall {
  static blog(url, headers) {
    return axios.get(`${blogUrl}${url}`, { headers });
  }
  static getv2(url, id, headers) {
    return axios.get(`${urlPath}${url}${mode}${id}`, { headers });
  }
  static getnewv2(url, id, headers) {
    return axios.get(`${urlPath}${url}${urlPathMode}${id}`, { headers });
  }
  static postnewv2(url, id, options, headers) {
    return axios.post(`${urlPath}${url}${urlPathMode}${id}`, options, {
      headers,
    });
  }
  static postv2(url, id, options, headers) {
    return axios.post(`${urlPath}${url}${mode}${id}`, options, { headers });
  }
  static putv2(url, id, options, headers) {
    return axios.put(`${urlPath}${url}${mode}${id}`, options, { headers });
  }
  static deletev2(url, id, headers) {
    return axios.delete(`${urlPath}${url}${mode}${id}`, { headers });
  }
  static get(hospitalurl, mode, headers) {
    return axios.get(`${urlPath}${mode}${hospitalurl}`, { headers });
  }

  static post(hospitalurl, mode, options, headers) {  
    return axios.post(`${urlPath}${hospitalurl}${mode}`, options, { headers });
  }
}

export const apiCall = (options, filterEnv) => {
  const modifiedUrl = {
    ...options,
    url: `${filterEnv ? baseUrl : urlPath}${options.url}${mode}${
      options.hosID
    }`,
  };
  return axios(modifiedUrl);
};

export const apiCallOld = (options, filterEnv) => {
  const modifiedUrl = {
    ...options,
    url: `${filterEnv ? baseUrl : urlPath}${options.url}`,
  };
  return axios(modifiedUrl);
};
