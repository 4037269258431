import "./scss/App.scss";
import LogIn from "./pages/Doctor/Login";
import { ThemeProvider } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import { theme } from "./utility/themeSettings";
import NotFound from "./pages/not-found/NotFound";
import DetailsPage from "./pages/Doctor/DetailsPage";
import JoinPage from "./pages/Doctor/JoinPage";
import ErrorBoundary from "./pages/error-boundary/ErrorBoundary";
import GlobalContext from "./context/GlobalContext";
import PatientRecords from "./components/PatientRecords";
import { useEffect } from "react";
import VerifyBot from "./components/VerifyBot";
import HospitalErrorPage from "./pages/Doctor/HospitalErrorPage";
import Appointment from "./pages/Doctor/Appointment";
import SnackbarAlert from "./components/SnackbarAlert";
import { PatientpageContext } from "./context/GlobalContext";
import AddServices from "./components/AddServices";
import Prescription from "./pages/Doctor/Prescription";
import OverlayManager from "./OverlayManager/OverlayManager";
import Overlay from "./OverlayManager/Overlay";
import Order from "./components/Order";
import PatientNotes from "./pages/Doctor/PatientNotes";
function App() {
  return (
    <div className="wrapper-background">
      <div className="overflow-hidden app-screen">
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <GlobalContext>
              <Overlay>
                <Switch>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    component={LogIn}
                  />
                  <Route
                    exact
                    path="/home"
                    component={() => <DetailsPage pageTitle="Doctor Page" />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/appointment`}
                    component={() => <Appointment />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/prescription`}
                    component={() => <Prescription />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/add-services`}
                    component={() => <AddServices />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/add-services/order/:mode`}
                    component={() => <Order/>}
                  />
                  
                  <Route 
                  exact
                  path={`${process.env.PUBLIC_URL}/prescription/patient_notes`}
                  component={() => <PatientNotes />}
                  />

                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/records`}
                    component={() => <PatientRecords />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/home/:tabIndex`}
                    component={() => <DetailsPage pageTitle="Doctor Page" />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/join/:roomid`}
                    component={() => <JoinPage pageTitle="JoinPage" />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/hospitalError`}
                    component={() => <HospitalErrorPage />}
                  />
                  <Route component={NotFound} />
                </Switch>
                <OverlayManager />
                <SnackbarAlert />
                <VerifyBot />
              </Overlay>
            </GlobalContext>
          </ThemeProvider>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
