import React, { createContext, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Grid, Typography } from "@mui/material";
import { PatientpageContext } from "../../context/GlobalContext";
import Skeleton from "@mui/material/Skeleton";
import DoctorsAppointmentListItem from "../../components/DoctorsAppointementListItem";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useEffect } from "react";
import Switch from "../../Tools/Swtich";
import CalendarIcon from "../../static/images/Combined-Shape.svg";
import RecordIcon from "../../static/images/record.svg";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import PatientRecordIcon from "../../static/images/patient_records.svg";
import MeetIcon from "../../static/images/meet_icon.svg";
import { AppointmentNavigation } from "../../static/images/ImageManager";
import Button from "../../Tools/Button";
import RightArrow from "../../static/images/right_arrow.svg";
import { Navigation } from "../../utility/utility";
import styles from "../../scss/_nextButton.scss";
import DialogScreen from "../../Tools/DialogScreen";
import NextImage from "../../static/images/next-image.svg";
import SwipableButton from "../../components/SwipableButton";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";

const Appointment = () => {
  const [loader, setLoader] = useState(false);
  const doctorContext = useContext(PatientpageContext);
  const [date, setDate] = useState("");
  const [appointmentDetails, setAppointmentDetails] = useState("");
  const [showCalendar, setShowCalendar] = useState(
    appointmentDetails?.followup_allowed || false
  );
  
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const history = useHistory();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, null, window.location.herf);
      window.onpopstate = () => {
        window.history.go(1);
        doctorContext?.dialogController({
          open: true,
          content: (() => (
            <div className="appointment__screen__exit">
              Do you really wish to exit?
            </div>
          ))(),
          checkLoginPage: false,
          promptWindow: true,
          dialogAction: (() => (
            <div className="appointment__screen__exit__buttons">
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                  Ls.remove("pt_key");
                  Ls.remove("pt_token");
                  history.push({
                    pathname: "/",
                  });
                }}
                className="mr-2"
                text="Exit"
                containerClass="m-auto appointment__screen__verify"
              ></Button>
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                }}
                className="mr-2"
                text="CANCEL"
                containerClass="m-auto appointment__screen__verify"
              ></Button>
            </div>
          ))(),
          maxWidth: "sm",
          disableBackdropClick: true,
        });
      };
    }
    if (!appointment) {
      history.push({
        pathname: "/home",
        search: history?.location?.search,
      });
    } else {
      const AppointmentDetails = async () => {
        try {
          const AppointmentData = await ServiceCall.getv2(
            "/v2/doctor/appointment/details/",
            appointment?.appointmentItem?.appointment_id,
            {
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            }
          ).then((e) => {
            setAppointmentDetails(e.data?.appointment);
            setShowCalendar(e.data?.appointment?.followup_allowed);
          });
        } catch (err) {
          console.log("appointment Error", err);
          doctorContext.setState({
            ...doctorContext.state,
            msg: `${err?.response?.data?.message || err}`,
            type: "error",
          });
        }
      };
      AppointmentDetails();
    }
    return () => {
      if (typeof window !== "window") {
        setAppointmentDetails("");
        window.onpopstate = null;
      }
    };
  }, []);

  const handleNavigation = async (call, api) => {
    try {
      const data = await call(
        api,
        appointment?.appointmentItem?.appointment_id|| "",
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
        );
      if (data) {
        console.log(data);
      }
    } catch (error) {
      doctorContext.setState({
        ...doctorContext.state,
        msg:error.response.data.message || 'Internal Server Error',
        type: "error",
      });
    }
  };

  const handleClick = (name) => {
    let params = history?.location?.search?.replace(
      `&app_id=${appointment?.appointmentItem?.appointment_id}`,
      ""
    );
    switch (name) {
      case "View patient Records":
        history.push({
          pathname: "/records",
          search: params,
        });
        break;
      case "Meet Now":
        // handleNavigation(ServiceCall.putv2, "/v2/doctor/start/call/");
        history.push({
          pathname: `/join/${appointmentDetails?.room_id}`,
          search: history?.location?.search,
        });
        break;
      case "Cancel Appointment":
        handleNavigation(ServiceCall.postv2, "/v2/doctor/cancel/appointment/");
        break;
      case "Write/Edit Prescription":
        history.push({
          pathname: "/prescription",
          search: history?.location?.search,
        });
        break;
      case "Add Services":
        history.push({
          pathname: "/add-services",
          search: history?.location?.search,
        });
        break;
      default:
        history.push({
          pathname: "/home/0",
          search: params,
        });
    }
  };

  const handleNextPatient = async () => {
    setLoader(true);
    try {
      const data = await ServiceCall.postv2(
        "/v2/doctor/next/patient/",
        appointment?.appointmentItem?.appointment_id || "",
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (data) {
        setLoader(false);
        history.push({
          pathname: "/home/0",
          search: history?.location?.search,
        });
      }
    } catch (err) {
      setLoader(false);
      doctorContext.setState({
        ...doctorContext.state,
        msg: err.response.data.message,
        type: "error",
      });
    }
  };

  const DoctorsAppointmentList = ({
    appointmentDate,
    classes,
    appointmentDetails,
  }) => {
    const FollowUpComponent = ({
      appointmentDate,
      appointmentDetails,
      record = false,
    }) => {
      const CalendarMonth = ({ label, formate, handleChange, date }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="newappointment__container__calender__datePicker appointment__date__calendar__date">
              <MobileDatePicker
                label={label}
                inputFormat={formate}
                value={date}
                onChange={(value) => handleChange(value, showCalendar)}
                renderInput={(params) => <TextField {...params} />}
              />
              <img src={CalendarIcon} />
            </div>
          </LocalizationProvider>
        );
      };

      const handleFollowUP = (checked) => {
        setShowCalendar(checked);
        handleDateChange(
          dayjs(
            appointment?.appointmentItem?.appointment_date_time?.split[0]
          ).add(2, "days"),
          checked
        );
      };
      const handleDateChange = async (value, checked) => {
        try {
          const followUp = await ServiceCall.putv2(
            "/v2/doctor/appointment/followup/",
            appointmentDate?.appointment_id,
            {
              followup_by: value.format("YYYY-MM-DD"),
              followup_allowed: checked,
            },
            {
              "Content-Type": "application/json",
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            }
          );

          if (followUp) {
            setDate(followUp?.data?.followup_by);
            setShowCalendar(followUp?.data?.followup_allowed);
            // doctorContext.setState({
            //   ...doctorContext.state,
            //   msg: `Follow up By - ${followUp?.data?.followup_by}`,
            //   type: "success",
            // });
          }
        } catch (err) {
          doctorContext.setState({
            ...doctorContext.state,
            msg: `${err?.response?.data?.message || err}`,
            type: "error",
          });
        }
      };

      return (
        <div>
          <div className="appointment__date__card">
            <div className="appointment__date__text">
              <span>
                {dayjs(appointmentDate?.appointment_date_formatted).format(
                  "ddd,"
                )}
              </span>
              {dayjs(appointmentDate?.appointment_date_formatted).format(
                "D MMM YYYY"
              )}
            </div>
            {record ? (
              <div className="appointment__date__record">
                <span>Records</span>
                <img src={RecordIcon} />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="appointment__date__calendar">
            <Switch
              label="Allow Follow up? (No-Charge)"
              labelInput="remind_patient"
              checked={showCalendar}
              ContainerClass="prescriptionpage__medication__reminder__switch"
              handleChange={(e) => handleFollowUP(e.target.checked)}
            />
            {showCalendar ? (
              <CalendarMonth
                label="Follow-up By"
                formate={"YYYY-MM-DD"}
                date={date || appointmentDetails?.followup_by}
                handleChange={handleDateChange}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    };

    function renderAppointmentList(item) {
      return (
        <DoctorsAppointmentListItem
          index={0}
          appointmentItem={appointment?.appointmentItem}
          classes={classes}
          handleOnClick={() => {}}
        />
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <FollowUpComponent
            appointmentDate={appointmentDate}
            appointmentDetails={appointmentDetails}
          />
        </Grid>
        {renderAppointmentList()}
      </>
    );
  };

  return (
    <div className="appointmentpage">
      <DialogScreen />
      <Navigation
        text="Appointment"
        navClass="appointmentpage__navigation"
        handleClick={handleClick}
      />
      {/* <p className="appointmentpage__text">
        Select start date and end date from calendar to list appointments
      </p> */}
      {appointment ? (
        <DoctorsAppointmentList
          appointmentDate={appointment?.appointmentItem}
          appointmentDetails={appointmentDetails}
          classes={appointment?.classes}
        />
      ) : (
        <></>
      )}
      <div className="appointmentpage__navigation__list">
        {Array.isArray(AppointmentNavigation) &&
          AppointmentNavigation?.map((list) =>
            appointmentDetails[list?.show] ||
            list?.show === "pateint Records" ? (
              <Navigation
                text={list?.text || ""}
                image={list?.image || ""}
                close={true}
                navClass="appointmentpage__navigation__list__button"
                handleClick={() => handleClick(list.text)}
              />
            ) : (
              <>
                {!appointmentDetails && (
                  <Typography
                    component="div"
                    variant="h3"
                    style={{ width: "90%", margin: "auto" }}
                  >
                    {<Skeleton />}
                  </Typography>
                )}
              </>
            )
          )}
      </div>
      <div className="appointmentpage__navigation__next">
        <SwipableButton
          image={NextImage}
          onSuccess={handleNextPatient}
          text="Next Patient"
          text_unlocked={loader ? "loading...." : "unlocked"}
        />
      </div>
    </div>
  );
};

export default Appointment;
