import React, { useState, useEffect, useContext } from "react";
import { PatientpageContext } from "../../context/GlobalContext";
import { Ls } from "../../utility/utility";
import { ServiceCall } from "../../utility/service-call";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";
import Typography from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import PatientNoteIcon from "../../static/PatientNote.svg";
import AccountIcon from "../../static/images/AccountIcon.svg";
import { Height } from "@mui/icons-material";
import Deleteicon from "../../static/Deleteicon.svg";
import SubmitIcon from "../../static/SubmitIcon.svg";

const PatientNotes = () => {
  const history = useHistory();

  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitloader, setSubmitloader] = useState(false);
  const [deleteloader, setDeleteloader] = useState(false);
  const doctorContext = useContext(PatientpageContext);
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));

  const handleNoteChange = (event) => {
    setNewNote(event.target.value);
  };

  const handleNoteSubmit = async () => {
    if (newNote.trim() !== "") {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();
      const newNoteWithDate = `${formattedDate}: ${newNote}`;
      setSubmitloader(true);
      try {
        const response = await ServiceCall.postv2(
          "/v2/doctor/patient/personal/note/",
          appointment?.appointmentItem?.appointment_id,
          {
            note: newNote,
          },
          {
            "Content-Type": "application/json",
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (response.status === 200) {
          setSubmitloader(false);
          fetchData();
          setNotes([
            ...notes,
            {
              time: formattedDate,
              msg: newNote,
              appointment_id: appointment?.appointmentItem?.appointment_id,
            },
          ]);
          setNewNote("");
        }
      } catch (error) {
        setSubmitloader(false);
        // console.error("Error submitting note:", error);
      }
    }
  };

  const handleNoteDelete = async (note_key) => {
    // console.log("note key is deleted", note_key);
    // setLoading(true);
    setDeleteloader({ ...deleteloader, [note_key]: true });
    try {
      const response = await ServiceCall.deletev2(
        `/v2/doctor/remove/patient/personal/note/`,
        appointment?.appointmentItem?.appointment_id + `/${note_key}`,

        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
          "Content-Type": "application/json",
        }
      );

      if (response.status === 200) {
        // setLoading(false);
        setDeleteloader(false);
        fetchData();
        const updatedNotes = notes.filter(
          (note) => note.appointment_id !== note_key
        );
        setNotes(updatedNotes);
      }
    } catch (error) {
      // setLoading(false);
      setDeleteloader(false);

      // console.log("Error deleting note:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const patientNotes = await ServiceCall.getv2(
        "/v2/doctor/list/patient/personal/notes/",
        appointment?.appointmentItem?.appointment_id,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (patientNotes.status == 200) {
        setNotes(patientNotes.data);
        setLoading(false);
      }
      const patientNotesData = patientNotes.data;

      // console.log(patientNotes);
    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (appointment?.appointmentItem?.appointment_id) {
      fetchData();
    }
  }, []);

  const handlebuttonclick = () => {
    history.push("/prescription");
  };

  return (
    <div className="patient_note">
      <div className="patient-note-header">
        <h2>
          <button onClick={handlebuttonclick}>
            <img src={PatientNoteIcon} alt="patientNoteIcon" />
          </button>
          Patient Notes
        </h2>
        <div className="para">
          <p>
            *Please note, the recorded notes here are your personal diary on the
              patient, the notes will not be visible to patient or any other
            doctor.
          </p>
        </div>
        <div className="pnh-inner">
          <img src={AccountIcon} alt="accountIcon" />

          <p>{appointment?.appointmentItem?.patient_name}</p>
          <span className="pnh-time">
            ({appointment?.appointmentItem?.patient_age},{" "}
            {appointment?.appointmentItem?.patient_gender})
          </span>
        </div>
      </div>
      <div className="patient_cl">
        <ul>
          {notes.map((item, index) => (
            <li key={index}>
              <span className="note-time">{item.note_datetime}</span>
              <div className="notes-outer">
                <span className="note-text">{item.note}</span>
                <span
                  className="btn"
                  onClick={() => handleNoteDelete(item.note_key)}
                >
                  {deleteloader[item.note_key] ? (
                    <CircularProgress
                      style={{
                        height: "inherit",
                        width: "inherit",
                      }}
                    />
                  ) : (
                    <img src={Deleteicon} alt="deleteicon" />
                  )}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="write_notes">
        <input
          type="text"
          className="form-control"
          value={newNote}
          onChange={handleNoteChange}
          placeholder="Enter a new note"
        />
        <button onClick={handleNoteSubmit} disabled={submitloader}>
          {submitloader ? (
            <CircularProgress
              size="30px"
              style={{
                color: "#eee",
                borderRadius: "50px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
          ) : (
            <img src={SubmitIcon} alt="submitIcon" />
          )}
        </button>
      </div>

      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            opacity: "0.6",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
          onClick={() => {}}
        >
          <CircularProgress size="20px" color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

export default PatientNotes;
