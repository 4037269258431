import React, { useContext, useState } from "react";
import PrescriptionEditorNew from "../../components/PrescriptionEditorNew";
import { useHistory } from "react-router-dom";
import {
  Navigation,
  removeEmptyValues,
  datePicker,
  Ls,
} from "../../utility/utility";
import { PatientpageContext } from "../../context/GlobalContext";
import MuiAlert from "@mui/material/Alert";
import { Grid, Typography, Box } from "@mui/material";
import DoctorsAppointmentListItemNew from "../../components/DoctorsAppointmentListItemNew";
import Snackbar from "@mui/material/Snackbar";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import PrescriptionEditor from "../../components/PrescriptionEditor";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Slide from "@mui/material/Slide";
import DownArrowIcon from "../../static/images/down_arrow.svg";
import DialogScreen from "../../Tools/DialogScreen";
import { Skeleton } from "@mui/material";
import { Input } from "@mui/material";
import Stack from "@mui/material/Stack";
import { dose_admin_method } from "../../static/staticData/staticData";
import PrescriptionShareIcon from "../../static/images/prescriptionShare.png";
import Backdrop from "@mui/material/Backdrop";
import {
  dietary_instructions,
  dose_timings,
} from "../../static/staticData/staticData";
import { OverlayContext } from "../../OverlayManager/Overlay";
import { OverlayType } from "../../OverlayManager/Context";
import InputButton from "../../Tools/InputButton";
import Switch from "../../Tools/Swtich";
import CalendarMonth from "../../Tools/CalendarMonth";
import Button from "../../Tools/Button";
import { FormHelperText } from "@material-ui/core";
import dayjs from "dayjs";
import RightArrowIcon from "../../static/images/Arrow--right.svg";
import { ServiceCall } from "../../utility/service-call";
import CircularProgress from "@mui/material/CircularProgress";
import PrescriptionPrompt from "../../components/PrescriptionPrompt";
import { useEffect } from "react";
import EyeIcon from "../../static/images/eyeSvg.svg";
import ShareIcon from "../../static/images/shareSvg.svg";
import { medication_form } from "../../static/staticData/staticData";
import SwipableButton from "../../components/SwipableButton";
import { apiCall } from "../../utility/service-call";

export const PrescriptionContainer = ({
  updatePData,
  asyncData,
  appointment,
  oldValue,
  pdataObj,
  receiveNotes,
  medicationError,
  context,
  feildsReset,
  medicationData,
  handleChangePrescription,
}) => {
  const Overlay = useContext(context);
  const [dosage, setDosage] = useState(feildsReset?.dosage);
  const [duration, setDuration] = useState(feildsReset?.setDuration);
  const [medicationFormList, setMedicationFormList] = useState(medication_form);
  const doctorContext = useContext(PatientpageContext);

  const handleCheckDosage = () => {
    if (!!medicationData?.medication && !!medicationData?.medication_form) {
      Overlay.show(OverlayType.PrescriptionDosage, {
        setDosage,
        medicationData,
        handleChangePrescription,
      });
    } else {
      handleChangePrescription(medicationData?.medication, "medication");
      handleChangePrescription(
        medicationData?.medication_form,
        "medication_form"
      );
    }
  };

  // console.log("appointment",appointment)

  useEffect(() => {
    doctorContext
      ?.fetchMedicationType("/medication/types")
      .then(
        (item) => item && item?.records && setMedicationFormList(item?.records)
      );
  }, []);

  useEffect(() => {
    setDosage(feildsReset?.dosage);
    setDuration(feildsReset?.duration);
  }, [feildsReset]);
  return (
    <div className="prescriptionMedicianBox">
      <div className="prescriptionMedicianBox__split">
        <PrescriptionEditorNew
          fetchType="drugs_list"
          label="medication"
          editable={true}
          placeholder="Input Medication"
          updatePData={updatePData}
          classes="prescriptionMedicianBox__drug"
          asyncData={true}
          pdataObj={pdataObj}
          icon={DownArrowIcon}
          feildsReset={feildsReset}
          changeHandler={handleChangePrescription}
          receiveNotes={receiveNotes["drugs_list"] || []}
        />
        <PrescriptionEditorNew
          fetchType="medication/types"
          label="medication_form"
          data={medicationFormList}
          placeholder="Type"
          updatePData={updatePData}
          classes="prescriptionMedicianBox__type"
          // asyncData={true}
          feildsReset={feildsReset}
          icon={DownArrowIcon}
          changeHandler={handleChangePrescription}
        />
      </div>
      {(medicationError?.medication?.hasError ||
        medicationError?.medication_form?.hasError) && (
        <FormHelperText className="validation-error">
          {medicationError?.medication?.errorMsg ||
            medicationError?.medication_form?.errorMsg}
        </FormHelperText>
      )}
      <div className="mb-3 mt-2">
        <Input
          placeholder="Drug Composition (Optional)"
          value={medicationData?.drug_composition}
          onChange={(e) => {
            handleChangePrescription(e.target.value, "drug_composition");
          }}
          className="w-100 pr-3 font-12"
        />
      </div>
      <div className="pos-relative mb-4" onClick={handleCheckDosage}>
        <Input
          placeholder="Dosage"
          value={dosage}
          style={{ pointerEvents: "none" }}
          className="w-100 pr-3 font-12"
        />
        <div className="autocompLeteLoader pos-absolute">
          <img src={DownArrowIcon} />
        </div>
      </div>
      {medicationError?.dose_qty?.hasError && (
        <FormHelperText className="validation-error">
          Dosage cannot be empty
        </FormHelperText>
      )}
      <div
        className="pos-relative mb-4"
        onClick={() => {
          Overlay.show(OverlayType.PrescriptionSlider, {
            setDuration,
            handleChangePrescription,
            medicationData,
          });
        }}
      >
        <Input
          placeholder="Duration"
          value={duration}
          style={{ pointerEvents: "none" }}
          className="w-100 pr-3 font-12"
        />
        <div className="autocompLeteLoader pos-absolute">
          <img src={DownArrowIcon} />
        </div>
      </div>
      {medicationError?.duration?.hasError && (
        <FormHelperText className="validation-error">
          {medicationError?.duration?.errorMsg}
        </FormHelperText>
      )}
      {/* <button onClick={() => {Overlay.show(OverlayType.PrescriptionDosage,"");}}>Click Here</button> */}
    </div>
  );
};

const Prescription = () => {
  const history = useHistory();
  const doctorContext = useContext(PatientpageContext);
  const Overlay = useContext(OverlayContext);
  const setState = doctorContext.setState;
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const [addMore, setAddMore] = useState(false);
  const [recentRecords, setRecentRecords] = useState([]);
  const [selectedRecords, setToSelectRecords] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });
  const listMedication = doctorContext?.listMedications;
  const [sharePrescription, setSharePrescription] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, null, window.location.herf);
      window.onpopstate = () => {
        window.history.go(1);
        doctorContext?.dialogController({
          open: true,
          content: (() => (
            <div className="appointment__screen__exit">
              Do you really wish to exit?
            </div>
          ))(),
          checkLoginPage: false,
          promptWindow: true,
          dialogAction: (() => (
            <div className="appointment__screen__exit__buttons">
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                  Ls.remove("pt_key");
                  Ls.remove("pt_token");
                  history.push({
                    pathname: "/",
                  });
                }}
                className="mr-2"
                text="Exit"
                containerClass="m-auto appointment__screen__verify"
              ></Button>
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                }}
                className="mr-2"
                text="CANCEL"
                containerClass="m-auto appointment__screen__verify"
              ></Button>
            </div>
          ))(),
          maxWidth: "sm",
          disableBackdropClick: true,
        });
      };
      if (appointment?.appointmentItem?.appointment_id) {
        doctorContext?.appointmentNotes(
          appointment?.appointmentItem?.appointment_id
        );
        doctorContext.fetchPatientInfo(
          appointment?.appointmentItem?.appointment_id
        );
        doctorContext
          ?.listRecentMedication()
          .then((items) => items && setRecentRecords(items?.records));
        doctorContext?.initailLookups();
      }
    }
    return () => {
      if (typeof window !== "window") {
        window.onpopstate = null;
        doctorContext?.setPrescriptionSymptoms("");
        doctorContext?.setFetchPrescription("");
        doctorContext?.setListMedication &&
          doctorContext?.setListMedication({
            dose_unit: "",
            dose_admin_method: "",
            dietary_instructions: "",
            dose_timings: "",
          });
      }
    };
  }, []);

  useEffect(() => {
    if (
      doctorContext?.prescriptionSymptoms !== "" &&
      doctorContext?.fetchMedication !== ""
    ) {
      setPrescriptionPromptData(doctorContext?.fetchMedication?.medications);
      if (
        typeof doctorContext?.prescriptionSymptoms === "object" &&
        !doctorContext?.prescriptionSymptoms?.message
      ) {
        setReceiveNotes(doctorContext?.prescriptionSymptoms);
        setPData(doctorContext?.prescriptionSymptoms);
      }
    }
  }, [doctorContext?.prescriptionSymptoms, doctorContext?.fetchMedication]);

  const defaultMedicationData = {
    drug_id: "",
    drug_composition: "",
    medication: "",
    medication_form: "Tablet",
    dose_qty: 1,
    dose_unit: "Tablet",
    dose_admin_method: "P.O.(Orally)",
    dose_timings: "od (One time in a day)",
    dietary_instructions: "a.c. (Before Meals)",
    remind_patient: true,
    medication_start_date: dayjs(),
    side_effects_explained: true,
    duration: "",
    other_instructions: "",
  };

  const defaultMedicationError = {
    medication: {
      hasError: false,
      errorMsg: "",
    },
    medication_form: {
      hasError: false,
      errorMsg: "",
    },
    dose_qty: {
      hasError: false,
      errorMsg: "",
    },
    dose_unit: {
      hasError: false,
      errorMsg: "",
    },
    dose_admin_method: {
      hasError: false,
      errorMsg: "",
    },
    dose_timings: {
      hasError: false,
      errorMsg: "",
    },
    dietary_instructions: {
      hasError: false,
      errorMsg: "",
    },
    // remind_patient: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    medication_start_date: {
      hasError: false,
      errorMsg: "",
    },
    side_effects_explained: {
      hasError: false,
      errorMsg: "",
    },
    duration: {
      hasError: false,
      errorMsg: "",
    },
    // symptoms_list: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    // findings_list: {
    //   hasError: false,
    //   errorMsg: "",
    // },
  };

  const Loader = (text) => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: (
        <div className="prescripitionLoader">
          <h3>Please Wait...</h3>
          <p>{text}</p>
          <CircularProgress color="success" />
        </div>
      ),
      removeCloseButton: true,
    });
  };

  const ExtraFeilds = {
    dosage: "",
    duration: "",
    medication: "",
    medication_form: "Tablet",
  };
  const [medicationData, setMedicationData] = useState(defaultMedicationData);
  const [medicationError, setMedicationError] = useState(
    defaultMedicationError
  );
  const [feildsReset, setFeildsReset] = useState(ExtraFeilds);
  const [buttonLoader, setButtonLoader] = useState(false);
  // const [prescription, setPrescription] = useState(true);
  const [PrescriptionPromptData, setPrescriptionPromptData] = useState([]);

  const dateBeforeValidation = (value) => {
    let before = true;
    let today = dayjs();
    if (today.$y <= value.$y && today.$M <= value.$M && today.$D <= value.$D) {
      before = false;
    }
    return before;
  };

  const errorHandling = (value, key) => {
    let errorFeild = medicationError[key];

    switch (key) {
      case "tests_list":
        break;
      case "dose_qty":
      case "duration":
        if (value) {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = `Please fill ${key} properly`;
        }
        break;
      case "remind_patient":
      case "side_effects_explained":
        if (value) {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = `Please fill Reminders properly`;
        }
        break;
      case "medication_start_date":
        if (!dateBeforeValidation(value)) {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = `Date should not be before ${datePicker(
            "YYYY-MM-DD"
          )}`;
        }
        break;
      case "symptoms_list":
      case "findings_list":
        if (value?.length > 0) {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = "Please fill above section";
        }
        break;
      default:
        if (value?.toString()?.trim() !== "") {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = `${key} cannot be empty`;
        }
    }
    setMedicationError({ ...medicationError, [key]: errorFeild });
  };

  const [receiveNotes, setReceiveNotes] = useState({
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
  });

  const pdataObj = {
    symptoms_list: "symptoms",
    findings_list: "diseases",
    tests_list: "diagnosis",
    drugs_list: "therapies",
  };

  const [pData, setPData] = useState({
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
  });

  const handleClick = () => {
    history.push({
      pathname: "/appointment",
      search: history?.location?.search,
    });
  };

  function updatePData(value, type) {
    let addedValue = { ...pData, [type]: value };
    setPData(addedValue);
    // Object.keys(addedValue).map((item) => {
    //   errorHandling(addedValue[item], item);
    // });
    // checkDisable();
  }

  const handleChange = (e) => {
    let key = e.target.value;
    let value = e.target.checked;
    if (key !== "remind_patient") {
      errorHandling(value, key);
    }
    setMedicationData({
      ...medicationData,
      [key]: value,
    });
  };

  const handleChangeDate = (value, key) => {
    errorHandling(value, key);
    setMedicationData({
      ...medicationData,
      [key]: value,
    });
  };

  const handleChangePrescription = (value, key) => {
    switch (key) {
      case "medication":
        errorHandling(value?.medicine_name, key);
        setMedicationData({
          ...medicationData,
          drug_id: value?.drug_id || "",
          drug_composition: value?.drug_composition || "",
          [key]: value?.medicine_name,
        });
        break;
      case "drug_composition":
        setMedicationData({
          ...medicationData,
          [key]: value,
        });
        break;
      case "medication_form":
        errorHandling(value, key);
        setMedicationData({
          ...medicationData,
          dose_unit: value,
          [key]: value,
        });
        break;
      case "duration":
        errorHandling(value, key);
        setMedicationData({
          ...medicationData,
          [key]: value,
        });
        break;
      default:
        setMedicationData({
          ...medicationData,
          ...value,
        });
        setMedicationError({
          ...medicationError,
          ...key,
        });
    }
  };

  const handleSubmit = async () => {
    let formErrorbolean = false;
    Object.keys(medicationError).map((item) => {
      errorHandling(medicationData[item], item);
    });

    // Object.keys(pData).map((item) => {
    //   errorHandling(pData[item], item);
    // });
    Object.keys(medicationError).map((item) => {
      if (medicationError[item]?.hasError) {
        formErrorbolean = true;
        setSnackbar({
          ...snackbar,
          open: true,
          msg: `** ${medicationError[item]?.errorMsg}`,
        });
      }
    });

    if (!formErrorbolean) {
      setButtonLoader(true);
      try {
        const Prescription = await ServiceCall.putv2(
          "/v2/doctor/appointment/note/sft/",
          appointment?.appointmentItem?.appointment_id,
          JSON.stringify(pData),
          {
            "Content-Type": "application/json",
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        const updatedMedicationData = removeEmptyValues(medicationData);
        const Medication = await ServiceCall.putv2(
          "/v2/doctor/appointment/edit/medic/",
          appointment?.appointmentItem?.appointment_id,
          JSON.stringify({
            ...updatedMedicationData,
            dietary_instructions: listMedication?.dietary_instructions?.filter(
              (item) =>
                item?.term === updatedMedicationData?.dietary_instructions
            )[0]?.definition,
            dose_timings: listMedication?.dose_timings?.filter(
              (item) => item?.term === updatedMedicationData?.dose_timings
            )[0]?.definition,
            dose_admin_method: listMedication?.dose_admin_method?.filter(
              (item) => item?.term === updatedMedicationData?.dose_admin_method
            )[0]?.definition,
            dose_qty: updatedMedicationData?.dose_qty.toString(),
            remind_patient: updatedMedicationData?.remind_patient.toString(),
            medication_start_date:
              updatedMedicationData?.medication_start_date.format("YYYY-MM-DD"),
            side_effects_explained:
              updatedMedicationData?.side_effects_explained.toString(),
          }),
          {
            "Content-Type": "application/json",
            doc_key: Ls.get("docKey"),
            doc_secret: Ls.get("docToken"),
          }
        );

        if (Prescription && Medication) {
          setButtonLoader(false);
          doctorContext?.listRecentMedication().then((items) => {
            items &&
              Array.isArray(items?.records) &&
              !!items?.records?.length &&
              setRecentRecords(items?.records);
            // handleRecentMedication(items?.records[0]);
            setToSelectRecords("");
          });
          // setPrescription(false);
          setAddMore(true);
          setPrescriptionPromptData([
            ...PrescriptionPromptData,
            {
              data: Medication?.data?.medication_message,
              med_id: Medication?.data?.med_id,
              reminder: medicationData?.remind_patient,
            },
          ]);
          doctorContext?.medicationFetch(
            appointment?.appointmentItem?.appointment_id
          );
          // setState({
          //   msg: Medication?.data?.medication_message || "",
          //   type: "error",
          //   timer: null,
          //   vertical: "top",
          //   horizontal: "center",
          // });
        }
      } catch (err) {
        err &&
          setState({
            msg:
              err?.response?.data?.message ||
              "Network Error please try after some time",
            type: "error",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        setButtonLoader(false);
      }
    }
  };

  const handleSharePrescription = async () => {
    Loader("1. Creating Medication Reminder Schedule for Patient");
    let params = history?.location?.search?.replace(
      `&app_id=${appointment?.appointmentItem?.appointment_id}`,
      ""
    );
    try {
      const generateMedication = await ServiceCall.postv2(
        "/v2/doctor/medic/schedule/",
        appointment?.appointmentItem?.appointment_id,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (
        generateMedication?.data?.message ===
        "Medications Schedule created successfully."
      ) {
        Loader("2. Sharing the Prescription with Patient");

        const sharePrescription = await ServiceCall.postv2(
          "/v2/doctor/prescription/submission/",
          appointment?.appointmentItem?.appointment_id,
          "",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (sharePrescription) {
          Overlay.show(OverlayType?.ModalWindow, {
            data: (
              <div className="prescripitionLoader">
                <img src={PrescriptionShareIcon} />
                <p>{sharePrescription?.data?.message}</p>
              </div>
            ),
            removeCloseButton: false,
            handleClose: function close() {
              history.push({
                pathname: "/appointment",
                search: params,
              });
            },
          });
        }
      } else {
        Overlay.hide();
        setState({
          msg: generateMedication?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handlePrescriptionDownload = async () => {
    let url = "/v2/doctor/prescriptions/download/";
    Loader("");
    try {
      const PrescriptionGenerate = await ServiceCall.putv2(
        "/v2/doctor/generate/prescription/",
        appointment?.appointmentItem?.appointment_id,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (PrescriptionGenerate) {
        const PrescriptionDownload = await apiCall({
          url,
          method: "get",
          responseType: "blob",
          hosID: appointment?.appointmentItem?.appointment_id,
          headers: {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
            "Content-Type": "application/pdf",
          },
        });
        if (PrescriptionDownload) {
          Overlay.hide();
          let blob = new Blob([PrescriptionDownload.data], {
            type: "application/pdf",
          });
          let url = window.URL.createObjectURL(blob);
          Overlay.show(OverlayType?.ModalWindow, {
            data: (
              <div className="prescription__pdf">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                  <Viewer fileUrl={url} />
                </Worker>
                <div className="prescription__pdf__container">
                  <a
                    href={url}
                    target="_blank"
                    className="prescription__pdf__button"
                  >
                    <LocalPrintshopIcon/>
                  </a>
                  <a
                    download={`prescription-${appointment?.appointmentItem?.appointment_id}`}
                    href={url}
                    className="prescription__pdf__button"
                  >
                    <DownloadForOfflineIcon/>
                  </a>
                </div>
              </div>
            ),
            handleClose: ()=>{}
          });
          setSharePrescription(true);
        }
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleAddMore = () => {
    if (!selectedRecords) {
      setMedicationData(defaultMedicationData);
      // setPrescription(true);
      setMedicationError(defaultMedicationError);
      setFeildsReset(ExtraFeilds);
    }
    setAddMore((prev) => !prev);
  };

  const handleRecentMedication = (item) => {
    setToSelectRecords(item?.display_name);
    doctorContext?.fetchMedicationDosage(item?.medication).then((list) => {
      setMedicationData({
        ...medicationData,
        ...list?.record,
        dose_admin_method:
          doctorContext.listMedications?.dose_admin_method?.filter((item) =>
            item?.term.includes(list?.record?.dose_admin_method)
          )[0]?.term,
        dietary_instructions:
          doctorContext.listMedications?.dietary_instructions?.filter((item) =>
            item?.term.includes(list?.record?.dietary_instructions)
          )[0]?.term,
        dose_timings: doctorContext.listMedications?.dose_timings?.filter(
          (item) => item?.term.includes(list?.record?.dose_timings)
        )[0]?.term,
      });
      let durationFormate =
        list?.record?.dose_admin_method +
        " - " +
        list?.record?.dose_qty +
        list?.record?.dose_unit +
        " - " +
        list?.record?.dose_timings +
        "....";
      setFeildsReset({
        ...feildsReset,
        dosage: durationFormate,
        duration: list?.record?.duration,
        medication: list?.record?.medication,
        medication_form: list?.record?.medication_form,
      });
    });
  };

  const handleSetPrompt = async (msg, index) => {
    let newPrescriptionPrompt = PrescriptionPromptData;
    newPrescriptionPrompt = newPrescriptionPrompt
      .slice(0, index)
      .concat(newPrescriptionPrompt.slice(index + 1));
    try {
      const DeletePrescription = await ServiceCall.deletev2(
        "/v2/doctor/appointment/del/medic/",
        `${appointment?.appointmentItem?.appointment_id}/${msg?.med_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (DeletePrescription) {
        if (newPrescriptionPrompt?.length === 0) {
          // setPrescription(true);
          setAddMore(false);
        }
        setPrescriptionPromptData(newPrescriptionPrompt);
      }
    } catch (err) {
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: 3000,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const DoctorsAppointmentList = ({ appointmentDate, classes }) => {
    return (
      <DoctorsAppointmentListItemNew
        index={0}
        tabRoute="prescription"
        key={appointment?.appointmentItem?.appointment_id}
        appointmentItem={
          doctorContext?.patientInfo || appointment?.appointmentItem
        }
        classes={classes}
      />
    );
  };

  return (
    <div className="prescriptionpage">
      <DialogScreen />
      {doctorContext?.preTemplateLoader && (
        <Backdrop
          sx={{
            color: "#fff",
            opacity: "0.6",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={doctorContext?.preTemplateLoader}
          onClick={() => {}}
        >
          <CircularProgress size="20px" color="inherit" />
        </Backdrop>
      )}
      <Navigation
        text="Prescription"
        // close={true}
        navClass="appointmentpage__navigation"
        handleClick={handleClick}
      />
      <p className="prescriptionpage__text">
        {/* Click on the Prescription card to download and view */}
      </p>
      {appointment ? (
        <DoctorsAppointmentList
          appointmentDate={
            appointment?.appointmentItem?.appointment_date_formatted
          }
          classes={appointment?.classes}
        />
      ) : (
        <div>
          <Stack
            spacing={2}
            style={{ width: "96%" }}
            className="d-flex justify-content-around flex-row align-items-center m-auto"
          >
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="rectangular" width="80%" height={60} />
          </Stack>
        </div>
      )}
      <div className="prescription_editor">
        <PrescriptionEditor
          fetchType="symptoms_list"
          editable={true}
          placeholder={"Input Reported Problems"}
          changeHandler={updatePData}
          asyncData={true}
          classes={appointment?.classes}
          pdataObj={pdataObj}
          receiveNotes={receiveNotes["symptoms_list"] || []}
        />
        {medicationError?.symptoms_list?.hasError && (
          <FormHelperText className="validation-error">
            {medicationError?.symptoms_list?.errorMsg}
          </FormHelperText>
        )}
      </div>
      <div className="prescription_editor">
        <PrescriptionEditor
          fetchType="findings_list"
          editable={true}
          placeholder={"Input Your Findings"}
          changeHandler={updatePData}
          asyncData={true}
          classes={appointment?.classes}
          pdataObj={pdataObj}
          receiveNotes={receiveNotes["findings_list"] || []}
        />
        {medicationError?.findings_list?.hasError && (
          <FormHelperText className="validation-error">
            {medicationError?.findings_list?.errorMsg}
          </FormHelperText>
        )}
      </div>
      <div className="prescription_editor">
        <PrescriptionEditor
          fetchType="tests_list"
          editable={true}
          placeholder={"Input Recommended Tests"}
          changeHandler={updatePData}
          asyncData={true}
          classes={appointment?.classes}
          pdataObj={pdataObj}
          receiveNotes={receiveNotes["tests_list"] || []}
        />{" "}
      </div>
      <div className="presctiption_prompt">
        <PrescriptionPrompt
          data={PrescriptionPromptData}
          handleSetPrompt={handleSetPrompt}
        />
      </div>
      {addMore && PrescriptionPromptData.length > 0 ? (
        <></>
      ) : (
        <>
          {recentRecords && Array.isArray(recentRecords) ? (
            <div className="prescription_recent-medication">
              <p className="prescription_recent-heading">Recent Medications</p>
              <div className="prescription_recent-medication__scroll">
                {recentRecords?.map((item) => (
                  <div
                    className={`${
                      selectedRecords === item?.display_name
                        ? "prescription_recent-medication_button_selected"
                        : ""
                    } prescription_recent-medication_button`}
                    onClick={() => {
                      handleRecentMedication(item);
                    }}
                  >
                    {item?.display_name}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="prescriptionpage__container">
            <PrescriptionContainer
              updatePData={updatePData}
              appointment={appointment}
              asyncData={true}
              pdataObj={pdataObj}
              receiveNotes={receiveNotes}
              medicationError={medicationError}
              context={OverlayContext}
              feildsReset={feildsReset}
              medicationData={medicationData}
              handleChangePrescription={handleChangePrescription}
            />
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbar?.open}
              onClose={() => {
                setSnackbar({ ...snackbar, msg: "", open: false });
              }}
              autoHideDuration={3000}
            >
              <MuiAlert
                elevation={6}
                severity="error"
                onClose={() => {
                  setSnackbar({ ...snackbar, msg: "", open: false });
                }}
              >
                {snackbar?.msg}
              </MuiAlert>
            </Snackbar>
            <div className="prescriptionpage__lastSection">
              <div className="prescriptionpage__medication__reminder">
                <Switch
                  label="Patient Medication Reminder"
                  labelInput="remind_patient"
                  checked={medicationData?.remind_patient}
                  ContainerClass="prescriptionpage__medication__reminder__switch"
                  handleChange={handleChange}
                />
              </div>
              {medicationError?.remind_patient?.hasError && (
                <FormHelperText className="validation-error">
                  {medicationError?.remind_patient?.errorMsg}
                </FormHelperText>
              )}
              {medicationData?.remind_patient ? (
                <>
                  <div className="prescriptionpage__medication">
                    <label>Medication Start Date</label>
                    <CalendarMonth
                      label="Start Date"
                      labelInput="medication_start_date"
                      formate="YYYY-MM-DD"
                      date={medicationData?.medication_start_date}
                      handleChange={handleChangeDate}
                    />
                  </div>
                  {medicationError?.medication_start_date?.hasError && (
                    <FormHelperText className="validation-error">
                      {medicationError?.medication_start_date?.errorMsg}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <></>
              )}
              <div className="prescriptionpage__medication__reminder">
                <Switch
                  label="Side-Effects/Contradictions Explained"
                  labelInput="side_effects_explained"
                  checked={medicationData?.side_effects_explained}
                  ContainerClass="prescriptionpage__medication__reminder__switch"
                  handleChange={handleChange}
                />
              </div>
              {medicationError?.side_effects_explained?.hasError && (
                <FormHelperText className="validation-error">
                  {medicationError?.side_effects_explained?.errorMsg}
                </FormHelperText>
              )}
            </div>
          </div>
        </>
      )}
      <div className="prescriptionpage__button">
        <Button
          text={buttonLoader ? <CircularProgress size={16} /> : "SAVE"}
          containerClass={`${
            (addMore && PrescriptionPromptData.length > 0) || buttonLoader
              ? "prescriptionpage__button__disable"
              : ""
          } prescriptionpage__button__save`}
          handleClick={handleSubmit}
        />
        <Button
          text={
            !addMore && PrescriptionPromptData.length > 0
              ? "Cancel -"
              : "Add More +"
          }
          containerClass={`${
            PrescriptionPromptData?.length > 0
              ? ""
              : "prescriptionpage__button__disable"
          } prescriptionpage__button__add`}
          handleClick={handleAddMore}
        />
      </div>
      <div className="prescriptionpage__swipe">
        <Navigation
          text="View Prescription"
          navClass={`${
            PrescriptionPromptData?.length > 0
              ? ""
              : "prescriptionpage__swipe__disable"
          }  prescriptionpage__swipe_btn`}
          image={{ left: EyeIcon, right: RightArrowIcon }}
          close
          handleClick={handlePrescriptionDownload}
        />
        <Navigation
          text="Share Prescription"
          image={{ left: ShareIcon, right: RightArrowIcon }}
          close
          navClass={`${
            sharePrescription ? "" : "prescriptionpage__swipe__disable"
          } prescriptionpage__swipe_btn`}
          handleClick={handleSharePrescription}
        />
      </div>
    </div>
  );
};

export default Prescription;
