import React, { useEffect, useContext, useState } from "react";
import AppointmentTab from "./AppointmentTab";
import NoAppointmentGif from "../static/images/no-appointment.gif";
import { PatientpageContext } from "./../context/GlobalContext";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarIcon from "../static/images/Combined-Shape.svg";
import Gap from "../Tools/Gap";

const NewAppointmentTab = (props) => {
  const doctorContext = useContext(PatientpageContext);

  const handleChange = (value, label) => {
    let start =
      label == "StartDate" ? value : doctorContext?.appointmentDate?.StartDate;
    let end =
      label == "EndDate" ? value : doctorContext?.appointmentDate?.EndDate;


    doctorContext.getAppointment(start, end);
    doctorContext?.setAppointmentDate({
      ...doctorContext?.appointmentDate,
      [label]: value,
    });
  };

  const CalendarMonth = ({ label, formate, handleChange, date }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="newappointment__container__calender__datePicker">
          <MobileDatePicker
            label={label}
            inputFormat={formate}
            value={date}
            onChange={(value) => handleChange(value, label)}
            renderInput={(params) => <TextField {...params} />}
          />
          <img src={CalendarIcon} />
        </div>
      </LocalizationProvider>
    );
  };

  return (
    <div className="newappointment__container">
      <div className="newappointment__container__calender">
        {/* <Navigation text="Appointment" handleClick={()=>props.navigate(0)}/> */}
        <p className="newappointment__container__calender__text">
          Select start date and end date from calendar to list appointments
        </p>
        <div className="newappointment__container__calender__date">
          <CalendarMonth
            label="StartDate"
            formate="YYYY-MM-DD"
            date={doctorContext?.appointmentDate?.StartDate}
            handleChange={handleChange}
          />
          <CalendarMonth
            label="EndDate"
            formate="YYYY-MM-DD"
            date={doctorContext?.appointmentDate?.EndDate}
            handleChange={handleChange}
          />
        </div>
        <p className="newappointment__container__calender__text">
          Click on the appointment card to view or action
        </p>
      </div>
      <AppointmentTab
        toggleAppointment={props.toggleAppointment}
        showAppointment={props.showAppointment}
        appointmentList={props.appointmentList}
        classes={props.classes}
        appointMentLoader={props.appointMentLoader}
        navigation="/appointment"
        tabRoute="appointment"
        noDataText={
          <div>
            <img
              style={{ width: "100%" }}
              src={NoAppointmentGif}
              alt="no appointment found"
            />
            <p>No Appointment Found !</p>
          </div>
        }
      />
      <Gap size="3rem" />
    </div>
  );
};

export default NewAppointmentTab;
