import React, { useContext } from "react";
import { Grid, Typography, Box, Button, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CircularProgress from "@mui/material/CircularProgress";
import { Skeleton } from "@mui/material";
import * as dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DoctorsAppointmentList from "./DoctorsAppointmentList";
import AppointmentScreen from "./AppointmentScreen";
import Overlay from "./Overlay";
import FilterIcon from "../static/images/prescriptionFilter.svg";
import { PatientpageContext } from "./../context/GlobalContext";
import { Img } from "react-image";

export default function AppointmentTab({
  noDataNavbar = false,
  toggleAppointment,
  showAppointment,
  appointmentList,
  appointMentLoader,
  classes,
  doctorsList,
  noDataText,
  containerClass,
  tabRoute = "",
  noNavBar = false,
  navigation,
}) {
  const doctorContext = useContext(PatientpageContext);

  //check if pateintContext.profileData is empty or not
  const openAppointmentScreen = (appointment) => {
    if (doctorContext.profileData.length !== 0) {
      toggleAppointment();
      // showAppointment(appointment);
    } else {
      doctorContext.dialogController({
        open: true,
        content: (() => (
          <div className="text-center">
            <h3>Please update your profile before making an appointment.</h3>
          </div>
        ))(),
        dialogAction: (() => (
          <>
            <Button
              color="error"
              variant="contained"
              onClick={doctorContext.openProfileForm}
              className="mr-2"
            >
              Proceed
            </Button>
            <Button
              className={classes.blackBtn}
              variant="contained"
              onClick={doctorContext.handleDialogClose}
            >
              Maybe Later
            </Button>
          </>
        ))(),
        maxWidth: "sm",
        disableBackdropClick: true,
      });
    }
  };

  function renderAppointmentList(lists, classProps) {
    let groupByAppointmentDate = lists.reduce(function (l, a) {
      if (tabRoute === "prescription") {
        l[a.prescription_date] = l[a.prescription_date] || [];
        l[a.prescription_date]?.push(a);
      } else {
        l[a.appointment_date_formatted] = l[a.appointment_date_formatted] || [];
        l[a.appointment_date_formatted]?.push(a);
      }
      return l;
    }, Object.create(null));
    let aa = [];
    for (let i in groupByAppointmentDate) {
      let itemClone = groupByAppointmentDate[i];
      aa.push(
        <DoctorsAppointmentList
          key={i}
          appointmentDate={i}
          item={itemClone}
          classes={classProps}
          noNavBar={noNavBar}
          tabRoute={tabRoute}
          containerClass={containerClass}
          noDataNavbar={noDataNavbar}
          navigation={navigation}
        />
      );
    }

    return aa;
  }
  return (
    <Box className="px-sm-3 py-1">
      {!showAppointment && (
        <>
          {appointMentLoader === "loading" ? (
            <>
              <Stack
                spacing={2}
                style={{ width: "96%" }}
                className="d-flex justify-content-around flex-row align-items-center m-auto"
              >
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="rectangular" width="80%" height={60} />
              </Stack>
              <Stack
                spacing={2}
                style={{ width: "96%" }}
                className="d-flex justify-content-around flex-row align-items-center m-auto"
              >
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="rectangular" width="80%" height={60} />
              </Stack>
              <Stack
                spacing={2}
                style={{ width: "96%" }}
                className="d-flex justify-content-around flex-row align-items-center m-auto"
              >
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="rectangular" width="80%" height={60} />
              </Stack>
              <Stack
                spacing={2}
                style={{ width: "96%" }}
                className="d-flex justify-content-around flex-row align-items-center m-auto"
              >
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="rectangular" width="80%" height={60} />
              </Stack>
            </>
          ) : (
            <>
              {appointmentList.length === 0 ? (
                <>
                  {noDataNavbar && (
                    <div
                      className={`Appointment__date ${containerClass}__date`}
                    >
                      <div>
                        <span>{dayjs().format("ddd,")}</span>
                        {dayjs().format("D MMM YYYY")}
                      </div>
                      <Img src={FilterIcon} />
                    </div>
                  )}
                  <Typography
                    align="center"
                    variant="subtitle2"
                    className="pt-4 text-capitalize"
                    gutterBottom
                  >
                    {noDataText || "No appointment found."}
                  </Typography>
                </>
              ) : (
                <Grid
                  container
                  className={`mt-3 position-relative ${
                    showAppointment ? "d-none" : ""
                  } ${containerClass}__container`}
                >
                  {doctorContext.overlayLoading === "loading" && (
                    <Overlay>
                      <CircularProgress size="20px" color="success" />
                    </Overlay>
                  )}
                  {renderAppointmentList(appointmentList, classes)}
                </Grid>
              )}
            </>
          )}
        </>
      )}

      <Grid container item xs={12} sm={12}>
        {showAppointment && (
          <AppointmentScreen
            doctorsList={doctorsList}
            toggleAppointment={toggleAppointment}
          />
        )}
      </Grid>
    </Box>
  );
}
