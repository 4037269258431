import React, { useState, useContext, useEffect } from "react";
import AppointmentTab from "./AppointmentTab";
import SearchIcon from "../static/images/svg/SearchIcon";
import { ServiceCall } from "../utility/service-call";
import CalendarMonth from "../Tools/CalendarMonth";
import { PatientpageContext } from "../context/GlobalContext";
import dayjs from "dayjs";

const NewPrescriptionTab = (props) => {
  const [searchData, setSearchData] = useState("");
  const [appointmentList, setAppointmentList] = useState(props.appointmentList);
  const doctorContext = useContext(PatientpageContext);

  useEffect(()=>{
    setAppointmentList(props.appointmentList)
  },[props?.appointmentList?.length])

  const handleDateChange = (value, label) => {
    doctorContext?.setPrescriptionDate({
      ...doctorContext?.prescriptionDate,
      [label]: value,
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    let appointmentListUpdated = props.appointmentList;

    const updatedAppointmentList =
      Array.isArray(appointmentListUpdated) &&
      !!appointmentListUpdated?.length &&
      appointmentListUpdated?.filter(
        (item) =>
          item?.patient_name
            ?.toLowerCase()
            ?.includes(value?.trim()?.toLowerCase()) ||
          item?.patient_age?.toString()?.includes(value?.toString()?.trim()) ||
          item?.patient_gender
            ?.toLowerCase()
            ?.includes(value?.trim()?.toLowerCase())
      );

    setAppointmentList(updatedAppointmentList);
    setSearchData(value);
  };
  return (
    <div className="prescriptionContainer">
      <div className="prescriptionContainer__heading">
        Click on the Prescription card to download and view
      </div>
      <div className="prescriptionContainer__dates">
        <CalendarMonth
          labelInput="StartDate"
          formate="YYYY-MM-DD"
          date={doctorContext?.prescriptionDate?.StartDate}
          handleChange={handleDateChange}
          props={{
            maxDate:doctorContext?.prescriptionDate?.EndDate,
            closeOnSelect:true
          }}
        />
        <CalendarMonth
          labelInput="EndDate"
          formate="YYYY-MM-DD"
          date={doctorContext?.prescriptionDate?.EndDate}
          handleChange={handleDateChange}
          props={{
            maxDate:dayjs(),
            minDate:doctorContext?.prescriptionDate?.StartDate,
            closeOnSelect:true
          }}
        />
      </div>
      <div className="prescriptionContainer__search">
        <input
          type="text"
          placeholder="Search for Name, Age or Gender"
          onChange={(e) => handleSearch(e)}
          value={searchData}
        />
        <SearchIcon />
      </div>
      <AppointmentTab
        toggleAppointment={props.toggleAppointment}
        showAppointment={props.showAppointment}
        appointmentList={appointmentList}
        classes={props.classes}
        appointMentLoader={props.appointMentLoader}
        noDataText=" No prescription found."
        // noDataNavbar
        tabRoute="prescription"
        // noNavBar
        containerClass="prescriptionContainer"
        // navigation="/prescription"
      />
    </div>
  );
};

export default NewPrescriptionTab;
