import React, { useContext } from "react";
import { useHistory } from 'react-router-dom'
import { Grid } from "@mui/material";
import { Ls, ErrorComponent } from "../utility/utility";
import BloodGroupSvg from "../static/images/bloodgroup.svg";
import { OverlayContext } from "../OverlayManager/Overlay";
import { OverlayType } from "../OverlayManager/Context";
import RecordIcon from "../static/images/record.svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeviceThermostatRoundedIcon from "@mui/icons-material/DeviceThermostatRounded";
import { PatientpageContext } from "../context/GlobalContext";
import ImageAvatar from "./ImageAvatar";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';

const DoctorsAppointmentListItemNew = ({
  appointmentItem,
  tabRoute = "",
  index,
  handleOnClick,
}) => {
  const isPrescription = tabRoute === "prescription";
  const overlay = useContext(OverlayContext);
  const doctorContext = useContext(PatientpageContext);
  const history = useHistory()
  return (
    <Grid
      key={index + 1}
      className={`py-2 px-3 appointment__container prescription__container
      ${tabRoute === "patientRecords"
          ? "patientRecordsAppointment"
          : "prescriptionReportAppointment"
        }`}
      container
      spacing={2}
      item
      xs={11}
      justifyContent="space-around`"
      alignItems="center"
      style={{ cursor: "pointer" }}
    // onClick={() => handleOnClick(appointmentItem)}
    >
      <Grid sm={12} xm={12}>
        {/* {appointmentItem?.pt_profile_id ? (
          <ImageAvatar
            cardClass="patientRecordsAppointment_image"
            async
            imagePath={`/v2/doctor/get/patient/pic/`}
            name={`firstname lastname`}
            ID={appointmentItem?.pt_profile_id}
          />
        ) : ( */}
        <AccountCircleRoundedIcon
          sx={{ width: "100%", color: "#199a8e", fontSize: "4rem" }}
        />
        {/* )} */}
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        className=" appointment__container__middleSection"
      >
        <div className="font-13 px-1">
          <div className=" appointment__container__middleSection__box">
            <ErrorComponent
              value={appointmentItem?.patient_name}
              defaultt="UnKnown"
              errorClass="appointment__container__middleSection__error-name"
            />
            <div className="font-13 px-1 appointment__container__middleSection__gender">
              (
              <ErrorComponent
                value={appointmentItem?.patient_gender
                  ?.split("")[0]
                  .toUpperCase()}
                defaultt="NA"
                errorClass="appointment__container__middleSection__error-gender"
                isSpan
              />
              /
              <ErrorComponent
                value={appointmentItem?.patient_age}
                defaultt="NA"
                errorClass="appointment__container__middleSection__error-age"
                isSpan
              />
              )
            </div>
            <div className="appointment__container__middleSection__status prescription__container__middleSection__status">
              <div className="">
                <img src={BloodGroupSvg} />
              </div>
              <div className="appointment__container__middleSection__status__text">
                {appointmentItem?.patient_blood_group || "B+"}
              </div>
              {isPrescription ? (
                <BorderColorIcon
                  className="ml-2"
                  sx={{ fontSize: "18px", color: "#199a8e" }}
                  onClick={() => {
                    doctorContext.openPatientProfileForm();
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        item
        container
        xs={4}
        sm={4}
        className="appointment__container__lastsection prescription__container__lastsection"
      >
        {tabRoute === "prescription" ? (
          <div className="appointment__container__lastsection__container">
            <div
              className="appointment__date__record"
              onClick={() => {

                history.push({
                  pathname: "/prescription/patient_notes",
                  search: history?.location?.search,
                });
              }}
            >
              <span style={{ display: "flex" }}>
                Patient Notes
                <MedicationRoundedIcon sx={{ fontSize: "25px" }} />
              </span>
            </div>
            <div
              className="appointment__date__record"
              onClick={() => {
                overlay.show(OverlayType?.ClinicalFindings);
              }}
            >
              <span style={{ display: "flex" }}>
                Clinical Findings
                <DeviceThermostatRoundedIcon sx={{ fontSize: "30px" }} />
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default DoctorsAppointmentListItemNew;
