import React, { useEffect, useState, useContext } from "react";
import { Avatar } from "material-ui";
import { Ls, stringAvatar, Tokens } from "./../utility/utility";
import { apiCall } from "../utility/service-call";
import { Token } from "@mui/icons-material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { constants } from "../utility/constants";
import { PatientpageContext } from "../context/GlobalContext";

function ImageAvatar({
  cardClass,
  imagePath,
  name,
  size,
  ID,
  circularpattern = "rounded-circle",
  async,
}) {
  const [hasError, setErrors] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState(imagePath);
  const doctorContext = useContext(PatientpageContext);
  const [imageLoader, setImageLoader] = useState("loading");
  
  useEffect(() => {
    if (!async) {
      setImageLoader("loaded");
    } else if (doctorContext?.DynamicImageStore[ID]) {
      setImageLoader("loaded");
      setImgSrc(doctorContext?.DynamicImageStore[ID]?.image);
    } else {
      setImageError();
    }

    return () => {
      setImageLoader("loaded");
    };
  }, []);

  const setImageError = async function (e) {
    await apiCall({
      url: imagePath,
      method: "get",
      responseType: "blob",
      hosID: ID || constants.hospitalId("hosp_id"),
      headers: {
        doc_secret: Tokens.docToken(),
        doc_key: Tokens.dockey(),
      },
    })
      .then((res) => {
        setImageLoader("loaded");
        setImgSrc(URL.createObjectURL(res.data));
        if (ID) {
          doctorContext?.setDynamicImageStore({
            ...doctorContext?.DynamicImageStore,
            [ID]: {
              image: URL.createObjectURL(res.data),
            },
          });
        }
        console.log("searchData", URL.createObjectURL(res.data));
      })
      .catch((err) => setErrors(true));
  };

  return (
    <>
      {hasError ? (
        <div
          // src={imagePath}
          className={`${circularpattern} ${cardClass} d-flex align-items-center justify-content-center`}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <AccountCircleRoundedIcon
            sx={{ width: "100%", color: "#199a8e", fontSize: "4rem" }}
          />
          {/* {stringAvatar(name)["children"]} */}
        </div>
      ) : (
        <>
          <div
            className={`${circularpattern} ${cardClass}__container`}
            style={{ width: size, height: size }}
          >
            {imageLoader === "loaded" && (
              <img
                src={imgSrc}
                alt={name}
                className={`img-fluid ${cardClass}__container_img`}
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  objectFit: "cover",
                  color: "transparent",
                  textIndent: "10000px",
                  borderRadius: "50%",
                }}
                onError={() => setErrors(true)}
              />
            )}
          </div>
          {/* <Avatar
            size='40px'
            className='with image'
            alt={name}
            src={imagePath}
          ></Avatar> */}
        </>
      )}
    </>
  );
}

export default React.memo(ImageAvatar);
