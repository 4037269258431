import login1 from "./carousel1.png"
import login2 from "./login_carousel_2.png"
import login3 from "./login_carousel_3.png";
import Appointment from "./svg/AppointmentImage"
import Today from "./svg/TodayImage"
import Prescription from "./svg/Prescription"
import More from "./svg/More"
import Online from "./online.svg"
import Inperson from "./inperson.svg"
import Walkin from "./walkin.svg"
import banner1 from "./homebanner.png"
import PatientRecordIcon from "./patient_records.svg";
import MeetIcon from "./meet_icon.svg";
import EditPrescription from "./write_icon.svg";
import CloseAppointment from "./close_icon.svg";
import ServicesIcon from "./Group 613.svg"
import RightArrow from "./right_arrow.svg";

export const LoginImage = [{
  image:login1,
  text:"Manage all appointments at one place"
},
{
  image:login2,
  text:"Tools to improve clinical outcomes"
},{
  image: login3,
  text:"Intelligent insights of Patient’s Health"
}
]

export const NavbarImage ={
   appointment : Appointment,
   today : Today,
   prescription : Prescription,
   more : More
}

export const AppointmentIcons ={
   inperson : Inperson,
   online : Online,
   walkin : Walkin

}

export const HomeBanner = [
  {
    image:banner1
  },
  {
    image:banner1
  }
]

export const AppointmentNavigation = [
  {
    text: "View patient Records",
    image: { left: PatientRecordIcon, right: RightArrow },
    show: "pateint Records",
  },
  {
      text:"Meet Now",
      image: { left: MeetIcon, right: RightArrow },
      show : "show_meet_now_button"
    },
    {
      text: "Write/Edit Prescription",
      image: { left: EditPrescription, right: RightArrow },
      show: "show_prescription_button",
    },
    {
      text: "Add Services",
      image: { left: ServicesIcon, right: RightArrow },
      show: "show_add_services_button",
    },
  {
    text: "Cancel Appointment",
    image: { left: CloseAppointment, right: RightArrow },
    show: "show_cancel_appointment_button",
  },
];

// export default ImageManager  = () => {

// }

