import { useState, useEffect, useContext } from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  Button,
  MenuItem,
  TextField,
  Checkbox,
  InputLabel,
  Select,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import dayjs from "dayjs";
import InputSchedule from "./InputSchedule";
import ButtonTool from "../Tools/Button";
import { ServiceCall } from "./../utility/service-call";
import { constants } from "./../utility/constants";
import { Ls } from "./../utility/utility";
import NightlightRoundOutlinedIcon from "@mui/icons-material/NightlightRoundOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import { PatientpageContext } from "./../context/GlobalContext";
import Overlay from "./Overlay";
import CalendarMonth from "../Tools/CalendarMonth";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import CircularProgress from "@mui/material/CircularProgress";
import TooltipComponent from "./TooltipComponent";

export default function NonRecurring({ timeArr }) {
  const doctorContext = useContext(PatientpageContext);

  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const defaultSlotsDetails = {
    morning_start_time: "00.00",
    morning_end_time: "11.59",
    afternoon_start_time: "12.00",
    afternoon_end_time: "17.00",
    evening_start_time: "17.00",
    evening_end_time: "23.00",
    morning_max_time: "11.30",
    afternoon_max_time: "16.30",
    evening_max_time: "22.30",
  };
  const [slotsDetails, setSlotDetails] = useState({
    morning_start_time: "08.00",
    morning_end_time: "11.59",
    afternoon_start_time: "12.00",
    afternoon_end_time: "17.00",
    evening_start_time: "17.00",
    evening_end_time: "23.00",
  });
  const [slotDuration, setSlotDuration] = useState("15");
  const [declareOff, setDeclareOff] = useState(false);
  const [appointmentType, setAppointmentType] = useState("online");
  const [isChecked, setChecked] = useState({
    morning: false,
    afternoon: false,
    evening: false,
  });
  const [stIndex, setStIndex] = useState(timeArr.length - 1);
  const [appointmentLoader, setAppointmentLoader] = useState(false);

  const handleChange = function (e, type, label) {
    let newslots = { ...slotsDetails };
    newslots[label] = dayjs(e).format("HH.mm");
    if (type === "startTimeRec") {
      setSlotDetails(newslots);
      // setEndTimeRec(timeArr.length - 1);
    }
    if (type === "endTimeRec") {
      setSlotDetails(newslots);
    }
    if (type === "slotDuration") {
      setSlotDuration(e.target.value);
    }
  };
  const submitForm = function () {
    let filterTiming = { ...slotsDetails };
    Object.keys(isChecked)?.forEach((keys) => {
      if (!isChecked[keys]) {
        Object.keys(filterTiming)?.forEach((timingKey) => {
          if (timingKey.includes(keys)) {
            delete filterTiming[`${timingKey}`];
          }
        });
      }
    });
    let data = {
      ...filterTiming,
      is_available: !declareOff,
      slot_duration: slotDuration?.toString(),
    };
    submitNonRecuringData(data);
  };
  const submitNonRecuringData = async (data) => {
    setAppointmentLoader(true);
    try {
      const submitCall = await ServiceCall.putv2(
        `/v3/doctor/manage/day/schedule/`,
        `${constants.hospitalId("hosp_id")}/${appointmentType}/${selectedDate}`,
        data,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      ).then((res) => {
        setAppointmentLoader(false);
        doctorContext.dialogController({
          open: true,
          content: (() => (
            <div className="text-center">{res.data.message}</div>
          ))(),
          maxWidth: "sm",
          disableBackdropClick: true,
        });
      });
    } catch (err) {
      console.log(err);
      setAppointmentLoader(false);
    } finally {
      setAppointmentLoader(false);
    }
  };
  const getDoctorsSchedule = function (selectedDate, appointmentType) {
    setAppointmentLoader(true);
    doctorContext
      .getDoctorSchedule(selectedDate, appointmentType)
      .then((res) => {
        setAppointmentLoader(false);
        // console.log(convertTime(res.current_schedule));
        if (res?.message) {
          setSlotDetails({
            ...slotsDetails,
            message: res?.message,
          });
        } else {
          setChecked({
            ...isChecked,
            morning: res?.morning_start_time && res?.morning_end_time,
            afternoon: res?.afternoon_start_time && res?.afternoon_end_time,
            evening: res?.evening_start_time && res.evening_end_time,
          });
          setSlotDetails({
            ...slotsDetails,
            morning_start_time:
              res?.morning_start_time || slotsDetails?.morning_start_time,
            morning_end_time:
              res?.morning_end_time || slotsDetails?.morning_end_time,
            afternoon_start_time:
              res.afternoon_start_time || slotsDetails?.afternoon_start_time,
            afternoon_end_time:
              res?.afternoon_end_time || slotsDetails?.afternoon_end_time,
            evening_start_time:
              res?.evening_start_time || slotsDetails?.evening_start_time,
            evening_end_time:
              res?.evening_end_time || slotsDetails?.evening_end_time,
          });
          setDeclareOff(!res.is_available);
          setSlotDuration(res.slot_duration);
        }
      })
      .catch((err) => {
        console.log(err);
        setAppointmentLoader(false);
      });
  };

  const handleToogleChange = (e, label) => {
    setChecked({ ...isChecked, [label]: e.target.checked });
  };

  useEffect(() => {
    getDoctorsSchedule(selectedDate, appointmentType);
  }, [selectedDate, appointmentType]);

  const setDeclareSteps = function (e) {
    console.log("check", e.target.checked);
    setDeclareOff(e.target.checked);
  };
  return (
    <>
      {(doctorContext.doctorScheduleLoader === "loading" ||
        appointmentLoader) && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <Grid item xs={12} className="">
        <FormControl>
          <FormLabel
            className="fw-bold"
            sx={{ color: "#000 !important", fontSize: "18px" }}
          >
            Appointment Type:{" "}
            {/* <TooltipComponent
              title={
                "Select type of consultation appointment type you wish to set the schedule for. You can have different schedule for Online(Tele) and At Clinic (In-Person) appointment."
              }
            /> */}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={appointmentType}
            onChange={(e) => setAppointmentType(e.target.value)}
          >
            <FormControlLabel
              value="online"
              control={<Radio sx={{ color: "#199a8e !important" }} />}
              label="Tele-Consultation"
            />
            <FormControlLabel
              value="in-person"
              control={<Radio sx={{ color: "#199a8e !important" }} />}
              label="At Clinic"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <CalendarMonth
          label="Date"
          formate={"YYYY-MM-DD"}
          containerClass="mySchedule_calendar"
          date={selectedDate}
          handleChange={(value, label) =>
            setSelectedDate(value?.format("YYYY-MM-DD"))
          }
          InputProps={{
            inputProps: {
              min: dayjs().format("YYYY-MM-DD"),
              onKeyPress: (e) => {
                e.preventDefault();
              },
            },
          }}
        />
        {/* <TextField
          id="date"
          label="Date"
          className="w-100"
          type="date"
          InputProps={{
            inputProps: {
              min: dayjs().format("YYYY-MM-DD"),
              onKeyPress: (e) => {
                e.preventDefault();
              },
            },
          }}
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
      </Grid>
      <Grid container spacing={2} item xs={12}>
        {slotsDetails?.message ? (
          <Grid item xs={12}>
            <h4 className="text-center">{slotsDetails?.message}</h4>
          </Grid>
        ) : (
          <>
            {/* select date end */}
            {/* declare off start */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={declareOff}
                    onChange={(e) => setDeclareSteps(e)}
                    color="success"
                  />
                }
                label="Declare Off:"
                labelPlacement="start"
              />
              <TooltipComponent title="Click to declare the selected date as Holiday or Leave." />
            </Grid>
            {/* declare off end */}
            {/* start and end time start */}
            <InputSchedule
              isAvailable={declareOff}
              label="morning"
              onToogleChange={handleToogleChange}
              isChecked={isChecked?.morning}
              selectedDate={selectedDate}
              slotsDetails={slotsDetails}
              defaultSlotsDetails={defaultSlotsDetails}
              startTime={slotsDetails?.morning_start_time}
              endTime={slotsDetails?.morning_end_time}
              icon={
                <WbTwilightIcon
                  sx={{ color: !isChecked?.morning ? "#cccccc" : "" }}
                />
              }
              onHandleChange={handleChange}
            />
            <InputSchedule
              isAvailable={declareOff}
              label="afternoon"
              onToogleChange={handleToogleChange}
              isChecked={isChecked?.afternoon}
              selectedDate={selectedDate}
              defaultSlotsDetails={defaultSlotsDetails}
              slotsDetails={slotsDetails}
              startTime={slotsDetails?.afternoon_start_time}
              endTime={slotsDetails?.afternoon_end_time}
              icon={
                <LightModeIcon
                  sx={{ color: !isChecked?.afternoon ? "#cccccc" : "" }}
                />
              }
              onHandleChange={handleChange}
            />
            <InputSchedule
              isAvailable={declareOff}
              label="evening"
              onToogleChange={handleToogleChange}
              isChecked={isChecked?.evening}
              defaultSlotsDetails={defaultSlotsDetails}
              selectedDate={selectedDate}
              slotsDetails={slotsDetails}
              startTime={slotsDetails?.evening_start_time}
              endTime={slotsDetails?.evening_end_time}
              icon={
                <NightlightRoundOutlinedIcon
                  sx={{ color: !isChecked?.evening ? "#cccccc" : "" }}
                />
              }
              onHandleChange={handleChange}
            />
            {/* <Grid item xs={6} className="mt-3">
              <FormControl className="w-100" variant="standard">
                <InputLabel
                  id="startTimeRec"
                  sx={{ color: "#898A8D !important" }}
                >
                  Start Time
                </InputLabel>
                <Select
                  value={startTimeRec}
                  onChange={(e) => handleChange(e, "startTimeRec")}
                  labelId="startTimeRec"
                  fullWidth
                  disabled={declareOff}
                >
                  {timeArr &&
                    timeArr.map((item, index) => (
                      <MenuItem value={index} key={item.value}>
                        {item.data}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} className="mt-3">
              <FormControl variant="standard" className="w-100">
                <InputLabel
                  id="endTimeRec"
                  sx={{ color: "#898A8D !important" }}
                >
                  End Date
                </InputLabel>
                <Select
                  labelId="endTimeRec"
                  value={endTimeRec}
                  onChange={(e) => handleChange(e, "endTimeRec")}
                  fullWidth
                  disabled={declareOff}
                >
                  {timeArr &&
                    timeArr.map((item, index) => (
                      <MenuItem
                        disabled={index < startTimeRec + 1}
                        value={index}
                        key={item.value}
                      >
                        {item.data}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}
            {/* start and end time end */}
            {/* slot duration start */}
            <Grid item xs={12} className="mt-3">
              <FormControl variant="standard" className="w-100">
                <InputLabel sx={{ color: "#000 !important" }}>
                  Slot Duration (In Mins)
                  {/* <TooltipComponent
                    title={
                      "Select the meeting time (in Minutes) or alot duration for each appointment consultation between start and end time."
                    }
                  /> */}
                </InputLabel>
                <Select
                  value={slotDuration}
                  onChange={(e) => handleChange(e, "slotDuration")}
                  fullWidth
                  disabled={declareOff}
                >
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"15"}>15</MenuItem>
                  <MenuItem value={"20"}>20</MenuItem>
                  <MenuItem value={"30"}>30</MenuItem>
                  <MenuItem value={"60"}>60</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* slot duration end */}
            <Grid
              item
              xs={12}
              className="text-center justify-content-center mt-3"
            >
              <ButtonTool
                containerClass="m-auto "
                text="Save"
                handleClick={submitForm}
              />
              {/* <Button color="error" variant="contained" onClick={submitForm}>
                Save
              </Button> */}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
