import React from "react";

const Prescription = ({props}) => {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_46_1008)">
        <path
          d="M13.79 2.4955H11.5351V3.56388H13.7961C14.3693 3.56388 14.8339 4.0285 14.8339 4.60163V18.9C14.8339 19.474 14.3684 19.9386 13.7953 19.9386H2.10088C1.96448 19.9386 1.82942 19.9118 1.70341 19.8596C1.5774 19.8074 1.4629 19.7309 1.36646 19.6344C1.27001 19.538 1.19351 19.4235 1.14131 19.2975C1.08912 19.1715 1.06225 19.0364 1.06225 18.9V4.60163C1.06225 4.02763 1.52775 3.563 2.10088 3.563H4.36188V2.4955H2.107C1.54756 2.49574 1.01111 2.71807 0.615526 3.11365C0.219945 3.50924 -0.00239262 4.04569 -0.00262451 4.60513V18.8904C-0.00216081 19.4497 0.220279 19.9859 0.615835 20.3813C1.01139 20.7767 1.54772 20.9989 2.107 20.9991H13.7891C14.3486 20.9989 14.885 20.7766 15.2806 20.381C15.6762 19.9854 15.8985 19.4489 15.8988 18.8895V4.60425C15.8985 4.04482 15.6762 3.50836 15.2806 3.11278C14.885 2.7172 14.3486 2.49486 13.7891 2.49463L13.79 2.4955Z"
          fill={props?.color ||"#898A8D"}
        />
        <path
          d="M11.0294 1.94687H9.87525C9.87525 1.43053 9.67013 0.935336 9.30502 0.570226C8.93991 0.205117 8.44472 0 7.92837 0C7.41203 0 6.91684 0.205117 6.55173 0.570226C6.18662 0.935336 5.9815 1.43053 5.9815 1.94687H4.83525V4.62175H11.0241V1.94687H11.0294ZM9.05362 1.94687H6.80487V1.91888C6.80487 1.62044 6.92343 1.33423 7.13445 1.1232C7.34548 0.912178 7.63169 0.793625 7.93012 0.793625C8.22856 0.793625 8.51477 0.912178 8.7258 1.1232C8.93682 1.33423 9.05537 1.62044 9.05537 1.91888V1.94862V1.94687H9.05362ZM5.20362 7.448H13.4794V8.47175H5.20362V7.448ZM5.20362 11.7337H13.4794V12.7575H5.20362V11.7337ZM5.20362 15.9688H13.4794V16.9978H5.20362V15.9688ZM2.2995 6.78737H4.6445V9.13238H2.2995V6.78737ZM2.2995 11.0731H4.6445V13.4181H2.2995V11.0731ZM2.2995 15.3099H4.6445V17.6549H2.2995V15.3099Z"
          fill={props?.color || "#898A8D"}
        />
      </g>
      <defs>
        <clipPath id="clip0_46_1008">
          <rect width="15.75" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Prescription;
