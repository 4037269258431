import { useState, useContext, useEffect } from "react";
import {
  Grid,
  IconButton,
  FormControl,
  TextField,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ButtonTool from "../Tools/Button";
import InputSchedule from "./InputSchedule";
import dayjs from "dayjs";
import { ServiceCall } from "./../utility/service-call";
import { constants } from "./../utility/constants";
import { Ls } from "./../utility/utility";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import CalendarMonth from "../Tools/CalendarMonth";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LightModeIcon from "@mui/icons-material/LightMode";
import { PatientpageContext } from "./../context/GlobalContext";
import Overlay from "./Overlay";
import { CircularProgress } from "@mui/material";
import NightlightRoundOutlinedIcon from "@mui/icons-material/NightlightRoundOutlined";
import TooltipComponent from "./TooltipComponent";

export default function RecurringForm({ weekDays, timeArr }) {
  const doctorContext = useContext(PatientpageContext);

  const [weeks, setWeeks] = useState(weekDays);
  const [slotDuration, setSlotDuration] = useState("15");
  const [appointmentType, setAppointmentType] = useState("online");
  const [appointmentLoader, setAppointmentLoader] = useState(false);
  const [isChecked, setChecked] = useState({
    morning: false,
    afternoon: false,
    evening: false,
  });
  // const [slotsDetails, setSlotDetails] = useState({
  //   message: "",
  //   slot_duration: "15",
  //   is_available: true,
  // });

  const defaultSlotsDetails = {
    morning_start_time: "00.00",
    morning_end_time: "11.59",
    afternoon_start_time: "12.00",
    afternoon_end_time: "17.00",
    evening_start_time: "17.00",
    evening_end_time: "23.00",
    morning_max_time: "11.30",
    afternoon_max_time: "16.30",
    evening_max_time: "22.30",
  };
  const [slotsDetails, setSlotDetails] = useState({
    morning_start_time: "08.00",
    morning_end_time: "11.59",
    afternoon_start_time: "12.00",
    afternoon_end_time: "17.00",
    evening_start_time: "17.00",
    evening_end_time: "23.00",
  });

  const updateWeekDays = function (e, index) {
    let currentDay = weeks[index];
    currentDay.selected = e.target.checked;
    setWeeks((oldVal) => {
      oldVal[index] = currentDay;
      return oldVal;
    });
  };
  const [startTime, setStartTime] = useState(0);
  const [recurringDate, setRecurringDate] = useState(
    dayjs().add(365, "day").format("YYYY-MM-DD")
  );

  const [endTime, setEndTime] = useState(timeArr.length - 1);
  const handleChange = function (e, type, label) {
    let newslots = { ...slotsDetails };
    newslots[label] = dayjs(e).format("HH.mm");
    if (type === "startTimeRec") {
      setSlotDetails(newslots);
      // setEndTimeRec(timeArr.length - 1);
    }
    if (type === "endTimeRec") {
      setSlotDetails(newslots);
    }
    if (type === "slotDuration") {
      setSlotDuration(e.target.value);
    }
  };

  const handleToogleChange = (e, label) => {
    setChecked({ ...isChecked, [label]: e.target.checked });
  };

  useEffect(() => {
    getDoctorsRecurringSchedule(appointmentType);
  }, [appointmentType]);

  const getDoctorsRecurringSchedule = function (appointmentType) {
    setAppointmentLoader(true);
    doctorContext
      .getDoctorRecurringSchedule(appointmentType)
      .then((res) => {
        // console.log(res);

        setAppointmentLoader(false);
        // console.log(convertTime(res.current_schedule));
        if (res.message) {
          setSlotDetails({
            ...slotsDetails,
            message: res.message,
          });
        } else {

          setChecked({
            ...isChecked,
            morning: res?.morning_start_time && res?.morning_end_time,
            afternoon: res?.afternoon_start_time && res?.afternoon_end_time,
            evening: res?.evening_start_time && res.evening_end_time
          });
          let defaultWeek = weeks;
          Array?.isArray(res?.weekdays) &&
            res?.weekdays?.forEach((day) => {
              let currDate = weeks[day];
              currDate["selected"] = true;
              defaultWeek[day] = currDate;
            });
          setWeeks(defaultWeek);
          setAppointmentType(res?.appointment_type);
          // setDeclareOff(!res.is_available
          setSlotDuration(res.slot_duration);
          // setRecurringDate(res?.schedule_till_date);
          setSlotDetails({
            ...slotsDetails,
            morning_start_time:
              res?.morning_start_time || slotsDetails?.morning_start_time,
            morning_end_time:
              res?.morning_end_time || slotsDetails?.morning_end_time,
            afternoon_start_time:
              res.afternoon_start_time || slotsDetails?.afternoon_start_time,
            afternoon_end_time:
              res?.afternoon_end_time || slotsDetails?.afternoon_end_time,
            evening_start_time:
              res?.evening_start_time || slotsDetails?.evening_start_time,
            evening_end_time:
              res?.evening_end_time || slotsDetails?.evening_end_time,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setAppointmentLoader(false);
      });
  };

  const submitRecuringData = function () {
    let daysList = Object.values(weeks).reduce((acc, item) => {
      if (item.selected) {
        acc.push(item.value);
      }
      return acc;
    }, []);
    let filterTiming = { ...slotsDetails };
    Object.keys(isChecked)?.forEach((keys) => {
      if (!isChecked[keys]) {
        Object.keys(filterTiming)?.forEach((timingKey) => {
          if (timingKey.includes(keys)) {
            delete filterTiming[`${timingKey}`];
          }
        });
      }
    });
    let data = {
      // appointment_type: appointmentType,
      ...filterTiming,
      days_list: daysList,
      slot_duration: slotDuration,
      schedule_end_date: recurringDate,
    };

    setAppointmentLoader(true);
    ServiceCall.putv2(
      `/v3/doctor/manage/recurring/schedule/`,
      `${constants.hospitalId("hosp_id")}/${appointmentType}`,
      data,
      {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      }
    )
      .then((res) => {
        // console.log('res', res.data);
        setAppointmentLoader(false);
        doctorContext.dialogController({
          open: true,
          content: (() => (
            <div className="text-center">{res.data.message}</div>
          ))(),
          maxWidth: "sm",
          disableBackdropClick: true,
        });
      })
      .catch((err) => {
        setAppointmentLoader(false);
      });
  };
  return (
    <>
      {(doctorContext.doctorScheduleLoader === "loading" ||
        appointmentLoader) && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <Grid item container xs={12} className="pb-3">
        <Grid item xs={12} sx={{ fontWeight: "bold" }} className="pt-2">
          Appointment Type:{" "}
          {/* <TooltipComponent
            title={
              "Select type of consultation appointment type you wish to set the schedule for. You can have different schedule for Online(Tele) and At Clinic (In-Person) appointment."
            }
          /> */}
        </Grid>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={appointmentType}
            onChange={(e) => setAppointmentType(e.target.value)}
          >
            <FormControlLabel
              value="online"
              control={<Radio sx={{ color: "#199a8e !important" }} />}
              label="Tele-Consultation"
            />
            <FormControlLabel
              value="in-person"
              control={<Radio sx={{ color: "#199a8e !important" }} />}
              label="At Clinic"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item container spacing={2} xs={12}>
        {slotsDetails.message ? (
          <Grid item xs={12}>
            <h4 className="text-center">{slotsDetails.message}</h4>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sx={{ fontWeight: "bold" }} className="pt-1">
              Weekdays{" "}
              {/* <TooltipComponent
            title={
              "Click to select the days on which you would be available for consultation"
            }
          /> */}
            </Grid>
            <Grid item xs={12} className="d-flex flex-wrap pt-1">
              {Object.keys(weeks).map((item, index) => {
                return (
                  <FormLabel key={weeks[item].name + index}>
                    <Checkbox
                      onChange={(e) => updateWeekDays(e, index)}
                      value={item}
                      defaultChecked={weeks[index].selected}
                      sx={{ color: "#199a8e !important" }}
                      size="small"
                    ></Checkbox>
                    {weeks[item].name}
                  </FormLabel>
                );
              })}
            </Grid>
            {/* <Grid item xs={6} className="mt-2">
              <FormControl className="w-100" variant="standard">
                <InputLabel id="startTime">
                  Start Time{" "}
                </InputLabel>
                <Select
                  value={startTime}
                  onChange={(e) => handleChange(e, "startTime")}
                  labelId="startTime"
                  fullWidth
                >
                  {timeArr &&
                    timeArr.map((item, index) => (
                      <MenuItem value={index} key={item.value}>
                        {item.data}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} className="mt-2">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="endTime">
                  End Time{" "}
                </InputLabel>
                <Select
                  labelId="endTime"
                  value={endTime}
                  onChange={(e) => handleChange(e, "endTime")}
                  fullWidth
                >
                  {timeArr &&
                    timeArr.map((item, index) => (
                      <MenuItem
                        disabled={index < startTime + 1}
                        value={index}
                        key={item.value}
                      >
                        {item.data}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}
            <InputSchedule
              isAvailable={false}
              label="morning"
              onToogleChange={handleToogleChange}
              isChecked={isChecked?.morning}
              selectedDate={recurringDate}
              slotsDetails={slotsDetails}
              defaultSlotsDetails={defaultSlotsDetails}
              startTime={slotsDetails?.morning_start_time}
              endTime={slotsDetails?.morning_end_time}
              icon={
                <WbTwilightIcon
                  sx={{ color: !isChecked?.morning ? "#cccccc" : "" }}
                />
              }
              onHandleChange={handleChange}
            />
            <InputSchedule
              isAvailable={false}
              label="afternoon"
              onToogleChange={handleToogleChange}
              isChecked={isChecked?.afternoon}
              selectedDate={recurringDate}
              defaultSlotsDetails={defaultSlotsDetails}
              slotsDetails={slotsDetails}
              startTime={slotsDetails?.afternoon_start_time}
              endTime={slotsDetails?.afternoon_end_time}
              icon={
                <LightModeIcon
                  sx={{ color: !isChecked?.afternoon ? "#cccccc" : "" }}
                />
              }
              onHandleChange={handleChange}
            />
            <InputSchedule
              isAvailable={false}
              label="evening"
              onToogleChange={handleToogleChange}
              isChecked={isChecked?.evening}
              defaultSlotsDetails={defaultSlotsDetails}
              selectedDate={recurringDate}
              slotsDetails={slotsDetails}
              startTime={slotsDetails?.evening_start_time}
              endTime={slotsDetails?.evening_end_time}
              icon={
                <NightlightRoundOutlinedIcon
                  sx={{ color: !isChecked?.evening ? "#cccccc" : "" }}
                />
              }
              onHandleChange={handleChange}
            />
            <Grid item xs={12} className="position-relative mt-3">
              <FormControl variant="standard" className="w-100">
                <InputLabel
                  InputLabelProps={{ style: { pointerEvents: "auto" } }}
                >
                  Slot Duration (In Mins){" "}
                  <TooltipComponent title="Select the meeting time (in Minutes) or alot duration for each appointment consultation between start and end time." />
                </InputLabel>
                <Select
                  value={slotDuration}
                  onChange={(e) => handleChange(e, "slotDuration")}
                  fullWidth
                >
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"15"}>15</MenuItem>
                  <MenuItem value={"20"}>20</MenuItem>
                  <MenuItem value={"30"}>30</MenuItem>
                  <MenuItem value={"60"}>60</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} className="mt-2">
              <CalendarMonth
                label="Schedule Till (End Date)"
                formate="YYYY-MM-DD"
                containerClass="mySchedule_calendar"
                date={recurringDate}
                handleChange={(value, label) => {
                  setRecurringDate(value);
                  getDoctorsRecurringSchedule(appointmentType);
                }}
                InputProps={{
                  inputProps: {
                    min: dayjs().format("YYYY-MM-DD"),
                    onKeyPress: (e) => {
                      e.preventDefault();
                    },
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={1}>
          <TooltipComponent
            title={
              "Input the date (end date) till when you would like to create a recurring schedule."
            }
          />
        </Grid> */}
            <Grid
              item
              xs={12}
              className="text-center justify-content-center mt-3"
            >
              <ButtonTool
                containerClass="m-auto "
                text="Save"
                handleClick={submitRecuringData}
              />
              {/* <Button
            color="error"
            variant="contained"
            onClick={submitRecuringData}
          >
            Save
          </Button> */}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
