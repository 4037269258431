import React, { useContext } from "react";
import { Grid, Button } from "@mui/material";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { PatientpageContext } from "../context/GlobalContext";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { OverlayType } from "../OverlayManager/Context";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { OverlayContext } from "../OverlayManager/Overlay";
import { apiCall } from "../utility/service-call";
import CircularProgress from "@mui/material/CircularProgress";
import { Ls } from "../utility/utility";
import dayjs from "dayjs";

const PatientRecordsList = ({ records, index }) => {
  const doctorContext = useContext(PatientpageContext);
  const Overlay = useContext(OverlayContext);
  const handleDonwnloadPrescription = async () => {
    let url = "/v2/doctor/download/record/";
    try {
      Overlay.show(OverlayType?.ModalWindow, {
        data: (
          <div className="prescripitionLoader">
            <h3>Please Wait...</h3>
            <CircularProgress color="success" />
          </div>
        ),
        removeCloseButton: true,
      });
      const PrescriptionDownload = await apiCall({
        url,
        method: "get",
        responseType: "blob",
        hosID: `${records?.appointment_id}/${records?.file_name}`,
        headers: {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        },
      });

      if (PrescriptionDownload) {
        Overlay.hide();
        let a = document.createElement("a");
        let blob = new Blob([PrescriptionDownload.data], {
          type: "application/pdf",
        });
        let url = window.URL.createObjectURL(blob);
        Overlay.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescription__pdf">
              {PrescriptionDownload?.data?.type === "application/pdf" ?
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                <Viewer fileUrl={url} />
                </Worker> :
                <img style={{width:"100%", height:"60vh", objectFit:"contain"}} alt="prescription" src={url} />
              }
              <div className="prescription__pdf__container">
                {/* <a
                  href={url}
                  target="_blank"
                  className="prescription__pdf__button"
                >
                  <LocalPrintshopIcon />
                </a> */}
                <a
                  download={records?.file_name}
                  href={url}
                  className="prescription__pdf__button"
                >
                  <DownloadForOfflineIcon />
                </a>
              </div>
            </div>
          ),
          handleClose: () => {},
        });
        // a.href = url;
        // a.download = records?.appointment_id;
        // document.body.append(a);
        // a.click();
        // a.remove();
        // window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log("recordsError", err);
      Overlay.hide();
      err &&
        doctorContext.setState({
          msg: err?.response?.data?.message,
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
    }
  };

  return (
    <Grid
      key={index + 1}
      className={`py-2 px-3 appointment__container patientRecordsContainer`}
      container
      spacing={2}
      item
      xs={11}
      justifyContent="space-around`"
      alignItems="center"
      style={{ cursor: "pointer" }}
    >
      <Grid className="d-flex flex-column align" sm={4} xm={4}>
        <h3 style={{ color: "#199a8e", margin: "0" }}>
          {records?.category || ""}
        </h3>
        <span className="mt-1">{`${records?.file_name
          .split("_")[1]
          ?.slice(0, 10)}.${records?.file_name?.split('.')[1]||'jpg'}`}</span>
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        className=" appointment__container__middleSection"
      >
        <div className="font-13 px-1 d-flex flex-column w-100 align-items-center">
          <div className="patientRecordsContainer_time">
            {dayjs(records?.uploaded_on?.split(" ")[1], "HH:mm:ss").format(
              "hh:mm A"
            )}
          </div>
          <div className="patientRecordsContainer_date mt-1">
            {dayjs(records?.uploaded_on?.split(" ")[0]).format("D-MMM-YYYY")}
          </div>
        </div>
      </Grid>
      <Grid
        item
        container
        xs={4}
        sm={4}
        className={` appointment__container__lastsection`}
      >
        <div className="appointment__container__lastsection__container">
          <RemoveRedEyeIcon
            sx={{ width: "100%", color: "#199a8e", fontSize: "3rem" }}
            onClick={handleDonwnloadPrescription}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default PatientRecordsList;
