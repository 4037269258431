import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import CircularProgress from "@mui/material/CircularProgress";

const PrescriptionPrompt = ({ data, handleSetPrompt }) => {
  const [loader, setLoader] = useState(false);
  if (data && Array.isArray(data) && data.length > 0) {
    return (
      <div className="prescriptionPrompt">
        {data?.map((msg, index) => (
          <div className="prescriptionPrompt__container" key={index + 1}>
            <div
              className="dosage__close prescriptionPrompt__container__close "
              onClick={() => {
                handleSetPrompt(msg, index);
              }}
            >
              <CloseIcon />
            </div>
            <div className="prescriptionPrompt__container__box">
              {loader ? (
                <CircularProgress color="secondary" />
              ) : (
                <div className="prescriptionPrompt__container__box__msg">
                  {msg?.data || msg?.medication_message}
                </div>
              )}

              {msg?.reminder || msg?.remind_patient  && (
                <div className="prescriptionPrompt__container__box__icon">
                  <NotificationsActiveRoundedIcon />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
  return <></>;
};

export default PrescriptionPrompt;
