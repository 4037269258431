import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Dialog, Avatar } from "@mui/material";
import { Ls } from "../utility/utility";
import { PatientpageContext } from "./../context/GlobalContext";
import CloseIcon from "@mui/icons-material/Close";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { baseUrl, constants, getLocal } from "./../utility/constants";
import ProfileForm from "../components/ProfileForm";
import ImageAvatar from "./ImageAvatar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoImage: {
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
  },
}));

export default function Header({ pageTitle, page }) {
  const classes = useStyles();
  let history = useHistory();
  const { search } = useLocation();
  const doctorContext = useContext(PatientpageContext);
  const [auth, setAuth] = React.useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [rpath, setRpath] = React.useState("");


  const handleMenu = (event) => {
    setAnchorEl(true);
  };

  const redirectToPage = (val) => {
    // setRpath(val);
    history.push({
      pathname: "/home/1",
      search: search,
    });
  };
  const handleSignout = () => {
    doctorContext.logOutCall();
  };
  const renderDialogContent = (contentType, content) => {
    if (contentType) {
      if (contentType === "profileForm") {
        return <ProfileForm />;
      } else {
        return contentType;
      }
    } else {
      return content;
    }
  };
  const openProfileForm = () => {
    setAnchorEl(false);
    doctorContext.openProfileForm();
  };
  const redirectComponent = () => {
    return <Redirect to={`${process.env.PUBLIC_URL}/${search}`} />;
  };

  const redirectToDailySchedule = () => {
    setAnchorEl(null);
    doctorContext.setCurrentTab(1);
  };
  const checkLoginPage = () => {
    if (doctorContext.checkLoginPage && page === "login") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="header">
      {/* {doctorContext.hospitalError.hasError && redirectComponent()} */}
      <div className="headerContainer">
        <Dialog
          disableEnforceFocus
          open={doctorContext.appDialog.open && checkLoginPage()}
          maxWidth={doctorContext.appDialog.maxWidth}
          // fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={doctorContext.handleDialogClose}
          disableEscapeKeyDown={true}
          scroll={doctorContext.appDialog.scroll}
        >
          <div
            className={`d-flex justify-content-flex-end pb-2 ${
              doctorContext.appDialog.promptWindow ? "pt-4" : ""
            }`}
          >
            {!doctorContext.appDialog.promptWindow && (
              <IconButton
                onClick={doctorContext.handleDialogClose}
                sx={{
                  color: (theme) => theme?.palette?.black,
                }}
                component="span"
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>

          <div className="pb-5 px-3 px-sm-5 text-break">
            {doctorContext.appDialog.contentType
              ? renderDialogContent(
                  doctorContext.appDialog.contentType,
                  doctorContext.appDialog.content
                )
              : doctorContext.appDialog.content}
          </div>
          {doctorContext.appDialog.dialogAction && (
            <div className="pb-5 px-2 text-center">
              {doctorContext.appDialog.dialogAction}
            </div>
          )}
        </Dialog>

        <div>
          {page && page !== "login" && (
            <div className="headerContainer__box">
              <ImageAvatar
                async
                imagePath={`/v2/get/doctor/pic/`}
                name={`firstname lastname`}
                cardClass="header__avatar"
              />
              {doctorContext.profileData && (
                <div
                  className="pr-1 d-block w-100 font-11 cursor-pointer"
                  onClick={handleMenu}
                  onMouseLeave={() => {
                    setAnchorEl(false);
                  }}
                  onMouseOver={() => {
                    setAnchorEl(true);
                  }}
                >
                  Hi,{" "}
                  <span className="font-weight-bold text-capitalize">
                    Dr. {doctorContext.profileData.firstname}{" "}
                    {doctorContext.profileData.lastname}
                  </span>
                  <div
                    className={`${
                      anchorEl ? "d-flex" : "d-none"
                    } headerContainer__dropDown`}
                  >
                    <ul>
                      <li onClick={() => openProfileForm()}>Update Profile</li>
                      <li onClick={handleSignout}>Sign Out</li>
                    </ul>
                  </div>
                </div>
              )}
              {/* <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => openProfileForm()}>
                  Update Profile
                </MenuItem>
                <Divider variant="middle" />
                <MenuItem onClick={handleSignout}>Sign Out</MenuItem>
              </Menu> */}
              <div className="headerContainer__icons"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
