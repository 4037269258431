import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PatientpageContext } from "../context/GlobalContext";
import Stack from "@mui/material/Stack";
import { Skeleton, Grid } from "@mui/material";
import { Navigation } from "../utility/utility";
import { Ls } from "../utility/utility";
import DoctorsAppointmentListItemNew from "./DoctorsAppointmentListItemNew";
import PatientRecordsList from "./PatientRecordsList";
import { ServiceCall } from "../utility/service-call";

const PatientRecords = () => {
  const doctorContext = useContext(PatientpageContext);
  const history = useHistory();
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const setState = doctorContext.setState;
  const [loader, setLoader] = useState(false);
  const [medicalRecords, setMedicalRecords] = useState([]);

  useEffect(() => {
    handlePatientRecords();
  }, []);

  const handlePatientRecords = async () => {
    let newMedicalRecords = [];
    setLoader(true);
    try {
      const records = await ServiceCall.getv2(
        "/v2/doctor/list/patient/records/",
        `${appointment?.appointmentItem?.appointment_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (records) {
        setLoader(false);

        newMedicalRecords = records?.data?.medical_records?.reduce(
          (acc, curr) => {
            curr.appointment_id = appointment?.appointmentItem?.appointment_id;
            acc.push(curr);
            return acc;
          },
          []
        );
        setMedicalRecords(newMedicalRecords);
      }
    } catch (err) {
      setLoader(false);
      doctorContext?.setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const DoctorsAppointmentList = ({ classes }) => {
    function renderAppointmentList(item) {
      return (
        <DoctorsAppointmentListItemNew
          index={0}
          tabRoute="patientRecords"
          key={appointment?.appointmentItem?.appointment_id}
          appointmentItem={appointment?.appointmentItem}
          classes={classes}
        />
      );
    }

    return (
      <>
        <Grid item xs={12}>
          {/* <FollowUpComponent appointmentDate={appointmentDate} /> */}
        </Grid>
        {renderAppointmentList()}
      </>
    );
  };
  return (
    <div className="prescriptionpage">
      {" "}
      <Navigation
        text="Patient Records"
        navClass="appointmentpage__navigation"
        handleClick={() => {
          history.push({
            pathname: "/appointment",
            search: history?.location?.search,
          });
        }}
      />
      {appointment ? (
        <DoctorsAppointmentList
          appointmentDate={
            appointment?.appointmentItem?.appointment_date_formatted
          }
          classes={appointment?.classes}
        />
      ) : (
        <div>
          <Stack
            spacing={2}
            style={{ width: "96%" }}
            className="d-flex justify-content-around flex-row align-items-center m-auto"
          >
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="rectangular" width="80%" height={60} />
          </Stack>
        </div>
      )}
      <div className="mt-5">
        {loader ? (
          <>
            <Stack
              spacing={2}
              style={{ width: "96%" }}
              className="d-flex justify-content-around flex-row align-items-center m-auto"
            >
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton variant="rectangular" width="80%" height={60} />
            </Stack>
            <Stack
              spacing={2}
              style={{ width: "96%" }}
              className="d-flex justify-content-around flex-row align-items-center m-auto"
            >
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton variant="rectangular" width="80%" height={60} />
            </Stack>
            <Stack
              spacing={2}
              style={{ width: "96%" }}
              className="d-flex justify-content-around flex-row align-items-center m-auto"
            >
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton variant="rectangular" width="80%" height={60} />
            </Stack>
          </>
        ) : (
          <>
            {medicalRecords?.map((list, index) => (
              <PatientRecordsList records={list} index={index} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PatientRecords;
