import React from "react";

const TodayImage = ({props}) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 0.125C2.4212 0.125 1.62634 0.45424 1.04029 1.04029C0.45424 1.62634 0.125 2.4212 0.125 3.25V15.75C0.125 16.5788 0.45424 17.3737 1.04029 17.9597C1.62634 18.5458 2.4212 18.875 3.25 18.875H15.75C16.5788 18.875 17.3737 18.5458 17.9597 17.9597C18.5458 17.3737 18.875 16.5788 18.875 15.75V3.25C18.875 2.4212 18.5458 1.62634 17.9597 1.04029C17.3737 0.45424 16.5788 0.125 15.75 0.125H3.25ZM2.20833 15.75V4.29167H16.7917V15.75C16.7917 16.0263 16.6819 16.2912 16.4866 16.4866C16.2912 16.6819 16.0263 16.7917 15.75 16.7917H3.25C2.97373 16.7917 2.70878 16.6819 2.51343 16.4866C2.31808 16.2912 2.20833 16.0263 2.20833 15.75Z"
        fill={props?.color || "#898A8D"}
      />
    </svg>
  );
};

export default TodayImage;
