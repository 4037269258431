import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { OverlayContext } from "../OverlayManager/Overlay";

import { Grid, Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import { ServiceCall } from "./../utility/service-call";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import NotesIcon from "@mui/icons-material/Notes";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
// import { convertTime24to12, Ls } from '../utility/utility';
import ImageAvatar from "./ImageAvatar";
import DownloadIcon from "../static/images/download_icon.svg";
import RxoneImage from "../static/images/Rx_symbol-removebg-preview 1.svg";
import TimeIcon from "../static/images/timericon.svg";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { convertTime24to12, Ls, ErrorComponent } from "../utility/utility";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { constants } from "../utility/constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { PatientpageContext } from "../context/GlobalContext";
import PatientReportComponent from "./PatientReportComponent";
import { CircularProgress } from "@mui/material";
import { OverlayType } from "../OverlayManager/Context";
import TooltipComponent from "./TooltipComponent";
import dayjs from "dayjs";
import { AppointmentIcons } from "../static/images/ImageManager";
import JoinComponent from "./JoinComponent";
import DialogComponent from "./DialogComponent";
import { apiCall } from "./../utility/service-call";
import { Img } from "react-image";

const modalHeading = {
  editPrescription: "Edit Prescription",
  inperson: "In-Person Appointment",
  appointmentCall: "Online (Tele) Appointment",
};

export default function DoctorsAppointmentListItem({
  appointmentItem,
  classes,
  tabRoute = "",
  index,
  handleOnClick,
}) {
  const [selectedDetailIndex, setSelectedDetailIndex] = useState("");
  const doctorContext = useContext(PatientpageContext);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [openJoinModal, setOpenJoinModal] = useState(false);
  const [allowFollowup, setAllowFollowup] = useState(
    appointmentItem.followup_allowed
  );
  const [dialogChild, setDialogChild] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [disableShare, setdisableShare] = useState(true);
  const Overlay = useContext(OverlayContext);
  const [dialogType, setDialogType] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    dayjs(appointmentItem.appointment_date).add(3, "days").format("YYYY-MM-DD")
  );
  const [disableCalendar, setDisableCalendar] = useState(true);
  const history = useHistory();

  const handleDonwnloadPrescription = async () => {
    let url = "/v2/doctor/prescriptions/download/";
    try {
      const PrescriptionDownload = await apiCall({
        url,
        method: "get",
        responseType: "blob",
        hosID: appointmentItem?.appointment_id,
        headers: {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        },
      });

      if (PrescriptionDownload) {
        let blob = new Blob([PrescriptionDownload.data], {
          type: "application/pdf",
        });
        let url = window.URL.createObjectURL(blob);
        Overlay.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescription__pdf">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                <Viewer fileUrl={url} />
              </Worker>
              <div className="prescription__pdf__container">
                <a
                  href={url}
                  target="_blank"
                  className="prescription__pdf__button"
                >
                  <LocalPrintshopIcon />
                </a>
                <a
                  download={`prescription-${appointmentItem?.appointment_id}`}
                  href={url}
                  className="prescription__pdf__button"
                >
                  <DownloadForOfflineIcon />
                </a>
              </div>
            </div>
          ),
          handleClose: () => {},
        });
        // a.download = appointmentItem?.appointment_id;
        // document.body.append(a);
        // a.click();
        // a.remove();
        // window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log("error",err)
      err &&
        doctorContext.setState({
          msg:
            err?.response?.data?.message ||
            "Network Error please try after some time",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
    }
  };

  const joinLogic = () => {
    if (doctorContext.checkDisable(appointmentItem)) {
      doctorContext.dialogController({
        open: true,
        content: (
          <div className="text-center">
            <p className="font-weight-bold mb-2">
              You can join the meeting at scheduled time only.
            </p>
          </div>
        ),
        maxWidth: "xs",
        disableBackdropClick: true,
        promptWindow: true,
      });
    } else {
      if (appointmentItem.appointment_type !== constants.online) {
        openJoin("inperson");
      } else {
        openJoin("online");
      }
    }

    // openJoin()
  };

  const showPatientDetails = (index) => {
    if (selectedDetailIndex == index) {
      setSelectedDetailIndex("");
    } else {
      setSelectedDetailIndex(index);
    }
  };

  function rmApp(id) {
    let url = `/doctor/appointment/cancel/request/${id}`;
    ServiceCall.post(url, "", {
      doc_token: Ls.get("userToken"),
    })
      .then((res) => {
        doctorContext.dialogController({
          open: true,
          content: res.data.message,
          maxWidth: "sm",
        });
        doctorContext.getAppointment();
      })
      .catch((err) => {
        setDeleteLoader(false);
        if (err.response) {
          if (err.response.status === 401) {
            doctorContext.dialogController({
              open: true,
              content: err.response.data.message,
              maxWidth: "sm",
            });
          }
          setApiError("Failed with response " + err.response.data);
        } else if (err.request) {
          setApiError("Failed request " + err);
        } else {
          setApiError("Failed in general " + err);
        }
      });
  }

  const AppointmentIcon = ({ type }) => {
    if (type?.includes("Online")) {
      return <Img src={AppointmentIcons.online} />;
    } else if (type?.includes("In-Person")) {
      return <Img src={AppointmentIcons.inperson} />;
    }
    return <Img src={AppointmentIcons.walkin} />;
  };

  const removeAppointment = () => {
    doctorContext.handleDialogClose();
    setDeleteLoader(true);
    rmApp(appointmentItem.appointment_id);
  };
  const followUpSwitch = (e) => {
    setAllowFollowup(e.target.checked);
    if (e.target.checked) {
      updateFollowup(
        dayjs(appointmentItem.appointment_date)
          .add(3, "days")
          .format("YYYY-MM-DD")
      );
    }
  };
  const updateFollowup = (value) => {
    // PUT {{url}}/test/doctor/appointment/followup/status/<string:appointment_id>
    setDeleteLoader(true);
    let url = `/doctor/appointment/followup/status/${appointmentItem.appointment_id}`;
    ServiceCall.put(
      url,
      { followup_by: value },
      {
        doc_token: Ls.get("userToken"),
      }
    )
      .then((res) => {
        setDeleteLoader(false);
        // doctorContext.dialogController({
        //   open: true,
        //   content: res.data.message,
        //   maxWidth: 'sm'
        // })
      })
      .catch((err) => {
        setDeleteLoader(false);
      });
  };
  const removeAppointmentConfirm = () => {
    doctorContext.dialogController({
      open: true,
      content: (
        <div className="text-center">
          <p className="font-weight-bold mb-2">
            Do you want to cancel the appointment?
          </p>
          {/* <span className="subscript">
              If you cancel the appointment now, we might need to deduct the
              processing fee from the refund.
            </span> */}
        </div>
      ),
      dialogAction: (
        <Grid container>
          <Grid
            item
            className="justify-content-center w-100 text-center d-flex"
          >
            <Button
              variant="contained"
              color="error"
              className="mr-2"
              size="small"
              onClick={removeAppointment}
            >
              Proceed
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={doctorContext.handleDialogClose}
              size="small"
            >
              Nevermind
            </Button>
          </Grid>
        </Grid>
      ),
      maxWidth: "xs",
      disableBackdropClick: true,
      promptWindow: true,
    });
    // doctorContext.deleteRecord(recordItem.name);
  };
  const openJoin = (joinType) => {
    // doctorContext.dialogController({
    //   open: true,
    //   content: <JoinComponent classes={classes} dialogType={joinType} appointment={appointmentItem} />,
    //   maxWidth: "xl",
    //   dialogAction: null
    // });
    setDialogType(joinType);
    setDialogChild(
      <JoinComponent
        classes={classes}
        dialogType={joinType}
        appointment={appointmentItem}
      />
    );
  };

  const StatusButton = ({ type, color }) => {
    if (type === "Confirmed") {
      return (
        <button className="loginContainer_button appointment__container__status__button">
          {type}
        </button>
      );
    } else {
      return (
        <button className="loginContainer_button appointment__container__status__button button_token">
          {type}
        </button>
      );
    }
    return <></>;
  };

  const closeDialog = () => {
    setDialogChild(null);
  };
  const closeReportDialog = () => {
    setOpenReport(false);
  };
  function checkShareDisable() {
    setdisableShare(false);
  }
  return (
    <Grid
      key={index + 1}
      className={`py-2 px-3 appointment__container ${
        tabRoute === "prescription" ? "prescription__list__container" : ""
      }
      `}
      container
      spacing={2}
      item
      xs={11}
      justifyContent="space-around`"
      alignItems="center"
      style={{ cursor: "pointer" }}
      onClick={() => handleOnClick(appointmentItem)}
    >
      {/*report dialog start*/}
      {openReport && (
        <DialogComponent
          closeDialog={closeReportDialog}
          openProps={openReport}
          title={"Patient Record"}
          render={() => (
            <PatientReportComponent
              appointmentId={appointmentItem?.appointment_id}
            />
          )}
        ></DialogComponent>
      )}
      {/*report dialog end*/}
      {/* JoinComponent dialog start */}
      {dialogChild && (
        <DialogComponent
          closeDialog={closeDialog}
          openProps={dialogChild}
          maxWidth="xl"
          hideTitle={true}
          titleComponent={false}
          contentClass="px-3"
          contentStyle={{ display: "flex", alignItems: "stretch" }}
          render={() => dialogChild}
        ></DialogComponent>
      )}
      {/* JoinComponent dialog end */}

      {deleteLoader && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <Grid sm={4} xm={4}>
        {/* <ImageAvatar
          cardClass="appointment__container__avatar"
          // async
          imagePath={`/v2/doctor/get/patient/pic/`}
          name={`firstname lastname`}
          ID={appointmentItem?.pt_profile_id || ""}
        /> */}
        {tabRoute === "prescription" ? (
          <RemoveRedEyeIcon
            sx={{ width: "100%", color: "#199a8e", fontSize: "3rem" }}
            onClick={handleDonwnloadPrescription}
          />
        ) : (
          <AccountCircleRoundedIcon
            sx={{ width: "100%", color: "#199a8e", fontSize: "4rem" }}
          />
        )}
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        onClick={() => {
          showPatientDetails(index + 1);
        }}
        className=" appointment__container__middleSection"
      >
        {/* <IconButton title="">
          {index + 1 === selectedDetailIndex ? (
            <KeyboardDoubleArrowUpIcon fontSize="small" color="success" />
          ) : (
            <KeyboardDoubleArrowDownIcon fontSize="small" color="success" />
          )}
        </IconButton> */}
        {/* <span className="font-13 px-2">
          {convertTime24to12(appointmentItem?.appointment_time)}
        </span> */}
        {/* {appointmentItem?.is_followup && (
          <div className="px-2">
            <Chip
              label="Follow-up"
              size="small"
              color="success"
              variant="outlined"
            />
          </div>
        )} */}
        <div className="font-13 px-1">
          <div className=" appointment__container__middleSection__box">
            <ErrorComponent
              value={appointmentItem?.patient_name}
              defaultt="UnKnown"
              errorClass="appointment__container__middleSection__error-name"
            />
            <div className="font-13 px-1 appointment__container__middleSection__gender">
              (
              <ErrorComponent
                value={appointmentItem?.patient_gender == "Female" ? "F" : "M"}
                defaultt="NA"
                errorClass="appointment__container__middleSection__error-gender"
                isSpan
              />
              /
              <ErrorComponent
                value={appointmentItem?.patient_age}
                defaultt="NA"
                errorClass="appointment__container__middleSection__error-age"
                isSpan
              />
              )
            </div>
            <div className="appointment__container__middleSection__status">
              <div className="">
                <AppointmentIcon
                  type={
                    appointmentItem?.app_type ||
                    appointmentItem?.appointment_type
                  }
                />
              </div>
              <div className="appointment__container__middleSection__status__text">
                {(
                  appointmentItem?.app_type || appointmentItem?.appointment_type
                )
                  ?.replace("Scheduled ", "")
                  ?.replace("(", "")
                  ?.replace(")", "")}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      {/* followup start */}
      {/* <Grid item container xs={4} sm={4} className="font-13 px-2 py-2 py-sm-0">
        {!appointmentItem.is_followup && (
          <>
            <Grid ite4m xs={6}>
              <FormControlLabel
                value="Allow Follow-up"
                classes={{
                  label: 'font-13'
                }}
                control={
                  <Switch
                    onChange={followUpSwitch}
                    color="primary"
                    size="small"
                    checked={allowFollowup}
                  />
                }
                label="Allow Follow-up"
                labelPlacement="top"
              />3000
            </Grid>
            <Grid ite4m xs={6} className="pt-1">
              <label className="py-1">Follow-up By</label>
              <TextField
                id="date"
                className="w-100"
                type="date"
                disabled={!allowFollowup}
                InputProps={{
                  inputProps: {
                    className: 'font-13',
                    min: dayjs(appointmentItem.appointment_date)
                      .add(1, 'days')
                      .format('Do MMM, YYYY'),
                    onKeyPress: (e) => {
                      e.preventDefault();
                    }
                  }
                }}
                value={selectedDate}
                onChange={(e) => {
                  updateFollowup(e.target.value);
                  setSelectedDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                variant="standard"
              />
            </Grid>
          </>
        )}
      </Grid> */}
      {/* followup end */}
      <Grid
        item
        container
        xs={4}
        sm={4}
        className={` appointment__container__lastsection`}
      >
        <div className="appointment__container__lastsection__container">
          <div className="appointment__container__lastsection__status">
            <Img src={RxoneImage} />
            {appointmentItem?.prescription_status ? (
              <ErrorComponent
                value={
                  appointmentItem?.prescription_status === "Confirmed" ||
                  appointmentItem?.prescription_status === "Completed"
                    ? appointmentItem?.prescription_status
                    : ""
                }
                defaultt="Pending"
                errorClass="appointment__container__middleSection__error-age"
              />
            ) : (
              <ErrorComponent
                value="Completed"
                defaultt="Pending"
                errorClass="appointment__container__middleSection__error-age"
              />
            )}
          </div>
          <div className="appointment__container__lastsection__time">
            {tabRoute === "prescription" ? (
              <Rating
                name="read-only"
                size="small"
                className="mt-1"
                value={appointmentItem?.star_rating || 0}
                readOnly
              />
            ) : (
              <>
                <Img src={TimeIcon} />
                <span className="font-13 px-2">
                  Time{" "}
                  {appointmentItem?.appointment_time_formatted ||
                    appointmentItem?.prescription_date ||
                    ""}
                </span>
              </>
            )}
          </div>
          <div className="appointment__container__lastsection__button">
            {tabRoute === "prescription" ? (
              <div
                style={{ gap: "0.2rem" }}
                className="d-flex align-items-center"
              >
                {/* <div onClick={handleDonwnloadPrescription}>
                  <img src={DownloadIcon} />
                </div> */}
                {appointmentItem?.review_comments ? (
                  <TooltipComponent
                    icon={
                      <NotesIcon
                        sx={{
                          width: "100%",
                          color: "#199a8e",
                          fontSize: "2rem",
                        }}
                      />
                    }
                    title={appointmentItem?.review_comments}
                  ></TooltipComponent>
                ) : (
                  <NotesIcon
                    sx={{ width: "100%", color: "#dddddd", fontSize: "2rem" }}
                    disabled
                  />
                )}
              </div>
            ) : appointmentItem?.is_walkin_appointment &&
              appointmentItem?.is_inperson_appointment ? (
              <StatusButton
                type={appointmentItem?.appointment_token_no}
                color="#EAA235"
              />
            ) : (
              <StatusButton type={appointmentItem?.app_status} />
            )}
          </div>
        </div>
        {/* <div className="pt-1">
          <Button
            variant="contained"
            className={`mr-3 d-flex align-items-center ${
              doctorContext.timeDifference(appointmentItem) && (!appointmentItem?.show_prescription_button)  ? 'd-none' : 'btn-success'
            } `}
            size="small"
            onClick={() => openJoin('editPrescription')}
            endIcon={<ModeEditIcon sx={{ fontSize: 14 }} />}
            disabled={doctorContext.timeDifference(appointmentItem)}
          >
            Prescription <span className="pl-1 mb-n1"></span>
          </Button>
        </div> */}
        {/* join button */}
        {/* <div className="pt-1">
          <JoinButton
            indexNo={index}
            joinLogic={joinLogic}
            item={appointmentItem}
          ></JoinButton>
        </div> */}
        {/* <IconButton
            onClick={removeAppointmentConfirm}
            color="error"
            component="span"
            className="p-1"
            disabled={appointmentItem.cash_order}
          >
            <HighlightOffIcon fontSize="large" />
          </IconButton> */}
        {/* <TooltipComponent
            title={
              !appointmentItem.cash_order
                ? 'Click cross(cancel) to cancel scheduled appointments. Appointments can be cancelled up to 3 Hrs. past the scheduled appointment time. Full refunds will be made to Patient (User) in 3-5 business days.'
                : 'Appointments booked using Cash/Card can be canceled by the Facility Reception staff. Please contact facility staff if you wish to cancel the appointment.'
            }
          /> */}
      </Grid>
      {/* <Collapse
        component={Paper}
        in={index + 1 === selectedDetailIndex}
        className={`w-100 ${
          index + 1 === selectedDetailIndex ? 'mt-2' : ''
        } bg-white rounded`}
      >
        <PatientReportComponent
          appointmentId={appointmentItem.appointment_id}
        />
      </Collapse> */}
    </Grid>
  );
}
