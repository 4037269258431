import React from "react";

export default function serviceWorker() {
  if ("serviceWorker" in navigator) {
    let swUrl = `/serviceW.js`;
    window.addEventListener('load', function() {
      navigator.serviceWorker
        .register(swUrl,{scope: './'})
        .then((e) => {

        })
        .catch((err) => {
        });
    }
    )
  }
}
