import React from "react";

const AppointmentImage = ({props}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.64018 0V7M15.2462 0V7M3.9773 10.5H7.95457M15.9091 10.5H11.9318M3.9773 14.7H7.95457M11.9318 14.7H15.9091M1.98866 3.5H17.8978C18.2494 3.5 18.5866 3.6475 18.8352 3.91005C19.0838 4.1726 19.2235 4.5287 19.2235 4.9V18.9C19.2235 19.2713 19.0838 19.6274 18.8352 19.8899C18.5866 20.1525 18.2494 20.3 17.8978 20.3H1.98866C1.63705 20.3 1.29984 20.1525 1.05121 19.8899C0.80258 19.6274 0.662903 19.2713 0.662903 18.9V4.9C0.662903 4.5287 0.80258 4.1726 1.05121 3.91005C1.29984 3.6475 1.63705 3.5 1.98866 3.5V3.5Z"
        stroke={props?.color || "#898A8D"}
        stroke-width="1.4"
      />
    </svg>
  );
};

export default AppointmentImage;
