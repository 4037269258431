import React from "react";

const More = ({ props }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 11.375C3.98325 11.375 4.375 10.9832 4.375 10.5C4.375 10.0168 3.98325 9.625 3.5 9.625C3.01675 9.625 2.625 10.0168 2.625 10.5C2.625 10.9832 3.01675 11.375 3.5 11.375Z"
        stroke={props?.color || "#898A8D"}
        stroke-width="1.75"
      />
      <path
        d="M10.5 11.375C10.9832 11.375 11.375 10.9832 11.375 10.5C11.375 10.0168 10.9832 9.625 10.5 9.625C10.0168 9.625 9.625 10.0168 9.625 10.5C9.625 10.9832 10.0168 11.375 10.5 11.375Z"
        stroke={props?.color || "#898A8D"}
        stroke-width="1.75"
      />
      <path
        d="M17.5 11.375C17.9832 11.375 18.375 10.9832 18.375 10.5C18.375 10.0168 17.9832 9.625 17.5 9.625C17.0168 9.625 16.625 10.0168 16.625 10.5C16.625 10.9832 17.0168 11.375 17.5 11.375Z"
        stroke={props?.color || "#898A8D"}
        stroke-width="1.75"
      />
    </svg>
  );
};

export default More;
